import { TGSLogo } from "../../../../common/icons";

const ChartExportImageChartLogo = () => {
  return (
    <div className="tgs-logo-watermark-production-data">
      <TGSLogo />
    </div>
  );
};

export default ChartExportImageChartLogo;
