import {
  AxisRange,
  ChartRange,
  ICashFlowChartData,
  IChartData,
  IScatterPlotChartData,
} from "../../types/charts/chartType/chartType";

export const getChartRangeProperties = (
  layout: { [key: string]: number },
  chart?: IChartData | IScatterPlotChartData | ICashFlowChartData
): ChartRange => {
  const xAxisLower = layout["xaxis.range[0]"];
  const xAxisUpper = layout["xaxis.range[1]"];
  const yAxisLower = layout["yaxis.range[0]"];
  const yAxisUpper = layout["yaxis.range[1]"];
  const yAxis2Lower = layout["yaxis2.range[0]"];
  const yAxis2Upper = layout["yaxis2.range[1]"];
  const xLegend = layout["legend.x"];
  const yLegend = layout["legend.y"];

  let mappedXRange: AxisRange = [undefined, undefined];
  if (xAxisLower && xAxisUpper) {
    mappedXRange = [xAxisLower, xAxisUpper];
  } else if (chart?.chartRange?.xRange) {
    mappedXRange = chart.chartRange?.xRange;
  }

  let mappedYRange: AxisRange = [undefined, undefined];
  if (yAxisLower && yAxisUpper) {
    mappedYRange = [yAxisLower, yAxisUpper];
  } else if (chart?.chartRange?.xRange) {
    mappedYRange = chart.chartRange?.yRange;
  }

  let mappedY2Range: AxisRange | undefined = [undefined, undefined];
  if (yAxis2Lower && yAxis2Upper) {
    mappedY2Range = [yAxis2Lower, yAxis2Upper];
  } else if (chart?.chartRange?.xRange) {
    mappedY2Range = chart.chartRange?.yRange2;
  }

  let mappedXLegend = undefined;
  if (xLegend) {
    mappedXLegend = xLegend;
  } else if (chart?.chartRange?.xLegend) {
    mappedXLegend = chart.chartRange?.xLegend;
  }

  let mappedYLegend = undefined;
  if (yLegend) {
    mappedYLegend = yLegend;
  } else if (chart?.chartRange?.yLegend) {
    mappedYLegend = chart.chartRange?.yLegend;
  }

  return {
    xRange: mappedXRange,
    yRange: mappedYRange,
    autorange: false,
    ...(mappedY2Range && { yRange2: mappedY2Range }),
    ...(mappedXLegend && { xLegend: mappedXLegend }),
    ...(mappedYLegend && { yLegend: mappedYLegend }),
  };
};
