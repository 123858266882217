import { exportComponentAsPNG } from "react-component-export-image";

import useExportPanelStore from "../../store/exportPanel/exportPanelStore";
import usePanelsStore from "../../store/panels/panelsStore";
import useStore from "../../store/useStore";

const useExportImage = () => {
  const exportChart = useStore((state) => state.exportChart);
  const updateExportChart = useStore((state) => state.updateExportChart);
  const toggleExportPlotMessage = usePanelsStore(
    (state) => state.toggleExportPlotMessage
  );

  const updateExportImageLoading = useExportPanelStore(
    (state) => state.updateExportImageLoading
  );

  const exportImage = (
    chartId: string,
    chartTitle: string | undefined,
    chartType: string | undefined,
    //For Export Purposes
    chartRef: React.RefObject<HTMLDivElement | HTMLInputElement> //React Node Ref,
  ) => {
    if (chartRef) {
      const fileName =
        chartTitle === "" ? chartType + "_" + chartId : chartTitle;

      exportComponentAsPNG(chartRef, {
        fileName,
        html2CanvasOptions: {
          backgroundColor: "#272727",
        },
      }).finally(() => {
        if (!exportChart.length) {
          updateExportChart({ chartType: "", chartId: "", chartMode: "" });
        }
        toggleExportPlotMessage(false);
        updateExportImageLoading(false);
      });
    }
  };

  return { exportImage };
};

export default useExportImage;
