import { useMemo } from "react";

import { Tab, Tabs } from "@mui/material";

import { Country } from "../../../types/common/wells";
import {
  MapLayer,
  WellPanelTab,
  WellSystemData,
} from "../../../types/panels/wellPanel/wellPanel";

import {
  WELL_PANEL_FLAGS,
  WELL_PANEL_TAB,
} from "../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../store/panels/panelsStore";

import ChartsAndPlotsTab from "./tabs/ChartsAndPlotsTab";
import LogsAndDocsTab from "./tabs/LogsAndDocsTab";
import WellInfoTab from "./tabs/WellInfoTab";

interface WellPanelContentProps {
  data: WellSystemData;
  activePage: number;
  uwi: string;
  layer: MapLayer;
  groupedWellID: number;
  surfaceUWI: string;
  country: Country;
}
const WellPanelContent = ({
  data,
  activePage,
  groupedWellID,
  uwi,
  layer,
  surfaceUWI,
  country,
}: WellPanelContentProps) => {
  const updateWellPanelActiveTabByKey = usePanelsStore(
    (state) => state.updateWellPanelActiveTabByKey
  );

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: WellPanelTab
  ) => {
    updateWellPanelActiveTabByKey(layer, groupedWellID, data.wellId, newValue);
  };

  const shouldShowChartsAndPlots: boolean = useMemo(() => {
    return (
      Boolean(data.wellCardData[WELL_PANEL_FLAGS.HAS_PRODUCTION.key]) ||
      Boolean(data.wellCardData[WELL_PANEL_FLAGS.HAS_GUN_BARREL.key]) ||
      Boolean(data.wellCardData[WELL_PANEL_FLAGS.HAS_WELL_ECONOMICS.key])
    );
  }, [data]);

  const shouldShowLogsAndDocs: boolean = useMemo(() => {
    return (
      Boolean(data.wellCardData[WELL_PANEL_FLAGS.HAS_LAS.key]) ||
      Boolean(data.wellCardData[WELL_PANEL_FLAGS.HAS_RASTER.key]) ||
      Boolean(data.wellCardData[WELL_PANEL_FLAGS.HAS_WELL_FILE.key])
    );
  }, [data]);

  return (
    <>
      <Tabs
        value={data.activeTab}
        onChange={handleTabChange}
        className="well-panel-tab"
        TabIndicatorProps={{ style: { display: "none" } }}
        variant="fullWidth"
      >
        <Tab
          className={
            data.activeTab === WELL_PANEL_TAB.WELL_INFO_TAB
              ? "active"
              : "inactive"
          }
          value={WELL_PANEL_TAB.WELL_INFO_TAB}
          label="Well Info"
          wrapped={false}
        />
        {shouldShowLogsAndDocs && (
          <Tab
            className={
              data.activeTab === WELL_PANEL_TAB.LOGS_AND_DOCS_TAB
                ? "active"
                : "inactive"
            }
            value={WELL_PANEL_TAB.LOGS_AND_DOCS_TAB}
            label="LOGS & DOCS"
            wrapped={false}
          />
        )}

        {shouldShowChartsAndPlots && (
          <Tab
            className={
              data.activeTab === WELL_PANEL_TAB.PLOTS_CHARTS_TAB
                ? "active"
                : "inactive"
            }
            label="Charts"
            value={WELL_PANEL_TAB.PLOTS_CHARTS_TAB}
            wrapped={false}
          />
        )}
      </Tabs>
      <WellInfoTab
        hidden={data.activeTab !== WELL_PANEL_TAB.WELL_INFO_TAB}
        wellSystem={data}
        activePage={activePage}
        groupedWellID={groupedWellID}
        layer={layer}
      />
      {shouldShowLogsAndDocs && (
        <LogsAndDocsTab
          wellSystem={data}
          activePage={activePage}
          groupedWellID={groupedWellID}
          layer={layer}
          hidden={data.activeTab !== WELL_PANEL_TAB.LOGS_AND_DOCS_TAB}
          surfaceUWI={surfaceUWI}
          country={country}
        />
      )}
      {shouldShowChartsAndPlots && (
        <ChartsAndPlotsTab
          hidden={data.activeTab !== WELL_PANEL_TAB.PLOTS_CHARTS_TAB}
          wellSystem={data}
          activePage={activePage}
          groupedWellID={groupedWellID}
          uwi={uwi}
          layer={layer}
          productionExport={false}
        />
      )}
    </>
  );
};

export default WellPanelContent;
