import { useEffect, useMemo, useRef, useState } from "react";
import Plot from "react-plotly.js";

import { Data, PlotRelayoutEvent } from "plotly.js";

import {
  CashFlowChartProps,
  ICashFlowChartData,
  IChartTypeInfo,
  ITraceData,
} from "../../../../types/charts/chartType/chartType";

import { CHART_MODE_VIEW } from "../../../../constants/charts/chartModeView";
import {
  CHART_TYPES,
  MAX_WELLS_COUNT_ASSET_ECONOMICS,
} from "../../../../constants/charts/charts";

import useChartStore from "../../../../store/chart/chartStore";
import useAlertPopupStore from "../../../../store/map/alert/alertStore";
import useMapSelectionStore from "../../../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../../../store/modularity/modularityStore";

import useAnalysisChartRefetch from "../../../../customHooks/charts/useAnalysisChartRefetch";
import useCashFlowChartData from "../../../../customHooks/charts/useCashFlowChartData";
import useProductionDataChartTransformation from "../../../../customHooks/charts/useProductionDataChartTransformation";
import { useProductionPlotExport } from "../../../../customHooks/exports/useProductionPlotExport";
import useExportToExcel from "../../../../customHooks/useExportToExcel";

import {
  config,
  fontLayout,
  formatChartCustomTicks,
  generalLayout,
  legendLayout,
  marginLayout,
  xAxisLayout,
  yAxisLayout,
} from "../../../../utils/charts/layouts/CashFlowLayout";
import { getChartRangeProperties } from "../../../../utils/charts/plotlyLayout";
import { formatCashflowExport } from "../../../../utils/exports/cashflowExport";
import { truncateString } from "../../../../utils/stringUtils";

import { clone } from "../../../../utils";
import LoadingBackDrop from "../../../common/LoadingBackDrop";
import ChartExportImageTitle from "../../../panels/wellPanel/common/export/ChartExportImageTitle";
import ChartTypeHeader from "../chartTypeHeader/ChartTypeHeader";

const CashFlowChart: React.FC<CashFlowChartProps> = ({
  chartType,
  chartId,
  onChartFullScreenClose,
  chartMode,
  isChartExport = false,
}) => {
  const isFullScreenAnalysisPane = useModularityStore(
    (state) => state.isFullScreenAnalysisPane
  );
  const isFullScreenIndividualChart = useModularityStore(
    (state) => state.isFullScreenIndividualChart
  );
  const fullScreenChartId = useModularityStore(
    (state) => state.fullScreenChartId
  );

  const chartData = useChartStore((state) => state.chartData);
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );
  const getDataFlag = useChartStore((state) => state.chartDataRefetchFlag);
  const updateGetDataFlag = useChartStore(
    (state) => state.setChartDataRefetchFlag
  );

  const selectedWellUwis = useMapSelectionStore(
    (state) => state.selectedWellUwis
  );
  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const { refetch, updateModuleRefetch } = useAnalysisChartRefetch({
    chartMode,
    chartId,
    chartType,
  });
  const {
    cashFlowData,
    cashFlowChartTraceData,
    isLoading,
    getCashFlowChartData,
  } = useCashFlowChartData({ updateModuleRefetch });
  const { formatXTicks } = useProductionDataChartTransformation();
  const [plotlyDataInfo, setPlotlyDataInfo] = useState<Data[] | []>([]);
  const [plotlyLayout, setPlotlyLayout] = useState<any>();
  const [chartRendered, setChartRendered] = useState(false);
  const [currentChartData, setCurrentChartData] = useState<
    ICashFlowChartData | undefined
  >(undefined);
  const [currentTickData, setCurrentTickData] = useState<number[]>([]);
  const [shouldFormatXTicks, setShouldFormatXTicks] = useState(false);

  const chartRef = useRef<any>();

  const { exportToExcel } = useExportToExcel();
  const { generateExportFileName } = useProductionPlotExport();

  const onChartRelayout = (figure: Readonly<PlotRelayoutEvent>) => {
    if (isChartExport) {
      setChartRendered(true);
    }
    if (figure?.autosize) {
      //on initialization, first value of the callBack is just {autosize: true} so we should negate the call
      return;
    }
    setPlotlyLayout(figure);
  };

  const handleExportChart = () => {
    const fileName = generateExportFileName(
      chartType,
      chartId,
      undefined,
      currentChartData?.title
    );

    const formattedExcelTraceData = formatCashflowExport(
      cashFlowChartTraceData
    );
    exportToExcel(formattedExcelTraceData, fileName);
  };

  const handleResetChart = () => {
    const copiedChartData: IChartTypeInfo[] = clone(chartData);
    const modifiedChartData: IChartTypeInfo[] = copiedChartData.map(
      (chart: IChartTypeInfo) => {
        if (
          chart.chartId === chartId &&
          chart.objectType === CHART_TYPES.CASH_FLOW
        ) {
          chart.chartRange = undefined;
        }
        return chart;
      }
    );
    const updatedChartData: IChartTypeInfo | undefined = modifiedChartData.find(
      (data) => data.chartId === chartId
    );

    if (updatedChartData) {
      updateChartDataByKey(chartId, updatedChartData);
    }
  };

  const displayWarningMessage = () => {
    if (selectedWellUwis.length >= MAX_WELLS_COUNT_ASSET_ECONOMICS) {
      updateAlertState({
        severity: "warning",
        message:
          "Some charts not available for analysis since too many wells selected.",
      });

      updateModuleRefetch();
    }
  };

  useEffect(() => {
    if (!chartId) return;
    const chart = chartData?.find((data) => data.chartId === chartId) as
      | ICashFlowChartData
      | undefined;
    if (chart) {
      setCurrentChartData(chart);
      if (chart.chartData?.length) {
        let customTicksVal: number[] = [];
        let isXTicksFormatted = false;
        customTicksVal = formatChartCustomTicks(chart.chartData);
        isXTicksFormatted = formatXTicks(chart.chartData);
        setCurrentTickData(customTicksVal);
        setShouldFormatXTicks(!isXTicksFormatted);
      } else {
        setCurrentTickData([-Infinity, -Infinity, -Infinity]);
        setShouldFormatXTicks(false);
      }
    }
  }, [chartData]);

  useEffect(() => {
    if (
      refetch &&
      selectedWellUwis.length &&
      selectedWellUwis.length < MAX_WELLS_COUNT_ASSET_ECONOMICS
    ) {
      getCashFlowChartData({
        uwi: selectedWellUwis,
        includeFcfDetails: true,
      });
    }

    displayWarningMessage();
  }, [refetch]);

  useEffect(() => {
    if (!selectedWellUwis.length) {
      setPlotlyDataInfo([]);
      const modifyChartData: IChartTypeInfo[] = clone(chartData);
      const updatedChartData = modifyChartData.find(
        (data: IChartTypeInfo) => data.chartId === chartId
      );

      if (
        updatedChartData &&
        updatedChartData.objectType == CHART_TYPES.CASH_FLOW
      ) {
        updatedChartData.chartData = [];
        updatedChartData.chartRawData = null;

        updateChartDataByKey(chartId, updatedChartData);
      }
    }

    displayWarningMessage();
  }, [selectedWellUwis]);

  useEffect(() => {
    if (!cashFlowChartTraceData.length) {
      setPlotlyDataInfo([]);
      return;
    }

    if (!selectedWellUwis.length) {
      updateModuleRefetch();
      return;
    }

    const copiedChartData: IChartTypeInfo[] = clone(chartData);
    const modifiedChartData: IChartTypeInfo[] = copiedChartData.map(
      (chart: IChartTypeInfo) => {
        if (
          chart.chartId === chartId &&
          chart.objectType === CHART_TYPES.CASH_FLOW
        ) {
          chart.chartData = cashFlowChartTraceData;
          chart.chartRawData = cashFlowData;
        }
        return chart;
      }
    );
    const updatedChartData: IChartTypeInfo | undefined = modifiedChartData.find(
      (data) => data.chartId === chartId
    );

    if (updatedChartData) {
      updateChartDataByKey(chartId, updatedChartData);
    }

    updateModuleRefetch();

    if (getDataFlag.refetch) {
      updateGetDataFlag({ chartId: "", refetch: false });
    }
  }, [cashFlowChartTraceData]);

  useEffect(() => {
    const layout = plotlyLayout;
    if (!layout) return;
    if (layout && Object.keys(layout).length < 2) return;

    const copiedChartData: IChartTypeInfo[] = clone(chartData);

    const newChartData = copiedChartData.map((chart) => {
      if (
        chart.chartId === chartId &&
        chart.objectType === CHART_TYPES.CASH_FLOW
      ) {
        chart.chartRange = getChartRangeProperties(layout, chart);
      }
      return chart;
    });

    const updatedChartData: IChartTypeInfo | undefined = newChartData.find(
      (data) => data.chartId === chartId
    );

    if (updatedChartData) {
      updateChartDataByKey(chartId, updatedChartData);
    }
  }, [plotlyLayout]);

  useEffect(() => {
    if (!currentChartData) return;
    setPlotlyDataInfo(currentChartData.chartData);
  }, [currentChartData]);

  useEffect(() => {
    if (!currentChartData?.chartData) return;

    const copiedChartData: ITraceData[] = clone(currentChartData.chartData);
    const mappedData: Data[] = copiedChartData?.map((trace: ITraceData) => {
      let name = trace.name;
      if (chartMode === CHART_MODE_VIEW.DEFAULT) {
        name = truncateString(trace.name, 11);
      }
      const formattedNameWithUnit = `${name}, ${trace.unit}`;
      return { ...trace, name: formattedNameWithUnit };
    });

    setPlotlyDataInfo(mappedData);
  }, [
    currentChartData,
    chartMode,
    isFullScreenAnalysisPane,
    isFullScreenIndividualChart,
    fullScreenChartId,
  ]);

  return (
    <>
      {!isLoading ? (
        <>
          <ChartTypeHeader
            chartId={chartId}
            isChartExport={isChartExport}
            chartMode={chartMode}
            onChartFullScreenClose={onChartFullScreenClose}
            chartRendered={chartRendered}
            chartRef={chartRef}
            onChartExportData={handleExportChart}
          />
          <div className="plot-chart-container" id={chartId} ref={chartRef}>
            {isChartExport && (
              <ChartExportImageTitle
                chart={
                  currentChartData?.title === ""
                    ? `${chartType}_${chartId} | ${chartType.toUpperCase()}`
                    : `${currentChartData?.title} | ${chartType.toUpperCase()}`
                }
              />
            )}

            <Plot
              onDoubleClick={handleResetChart}
              divId={chartType}
              className="plot-chart"
              useResizeHandler={true}
              data={plotlyDataInfo}
              config={config}
              layout={{
                ...generalLayout(
                  chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS ||
                    chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART
                ),
                margin: {
                  ...marginLayout,
                },
                font: {
                  ...fontLayout,
                },
                legend: {
                  ...legendLayout(currentChartData?.chartRange),
                },
                xaxis: {
                  ...xAxisLayout(
                    currentChartData?.chartRange,
                    shouldFormatXTicks
                  ),
                },
                yaxis: {
                  ...yAxisLayout(currentChartData?.chartRange, currentTickData),
                },
              }}
              onRelayout={(figure: any) => {
                onChartRelayout(figure);
              }}
            />
          </div>
        </>
      ) : (
        <LoadingBackDrop
          className={`cash-flow-chart ${
            fullScreenChartId === chartId &&
            isFullScreenIndividualChart &&
            chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART
              ? "full-screen-chart"
              : ""
          } loader`}
          isOpen
        />
      )}
    </>
  );
};

export default CashFlowChart;
