import { CHART_TYPES } from "../../../constants/charts/charts";
import {
  PRODUCTION_PLOT,
  SCATTER_PLOT,
  TYPE_CURVE,
} from "../../../constants/constants";

import useStore from "../../../store/useStore";

import CashFlowChart from "../chartType/CashFlow/CashFlowChart";
import ProductionPlotChart from "../chartType/ProductionPlotChart";
import ScatterPlotChart from "../chartType/ScatterPlotChart";
import TypeCurveChart from "../chartType/TypeCurveChart";

const ExportChart = () => {
  const exportChart = useStore((state) => state.exportChart);

  return (
    <div className="export-chart">
      {exportChart.chartType === PRODUCTION_PLOT && (
        <ProductionPlotChart
          chartType={exportChart.chartType}
          chartId={exportChart.chartId}
          onChartFullScreenClose={() => null}
          chartMode={exportChart.chartMode}
          isChartExport={true}
        />
      )}
      {exportChart.chartType === TYPE_CURVE && (
        <TypeCurveChart
          chartType={exportChart.chartType}
          chartId={exportChart.chartId}
          onChartFullScreenClose={() => null}
          chartMode={exportChart.chartMode}
          isChartExport={true}
        />
      )}
      {exportChart.chartType === SCATTER_PLOT && (
        <ScatterPlotChart
          chartType={exportChart.chartType}
          chartId={exportChart.chartId}
          chartMode={exportChart.chartMode}
          onChartFullScreenClose={() => null}
          isChartExport={true}
        />
      )}
      {exportChart.chartType === CHART_TYPES.CASH_FLOW && (
        <CashFlowChart
          chartType={exportChart.chartType}
          chartId={exportChart.chartId}
          onChartFullScreenClose={() => null}
          chartMode={exportChart.chartMode}
          isChartExport={true}
        />
      )}
    </div>
  );
};

export default ExportChart;
