import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { VariableSizeList, areEqual } from "react-window";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import classNames from "classnames";
import moment from "moment/moment";

import {
  CheckboxProps,
  GroupedDates,
  VirtualizedDateRowList,
} from "../../../../../../types/grid";

import {
  FILTER_BLANK_TEXT_DISPLAY,
  FILTER_IS_EMPTY_OPERATOR,
} from "../../../../../../constants/grid";

import usePrevious from "../../../../../../customHooks/usePrevious";

// Note: Date Tree Component uses React Window (Variable Size) for virtualization of list
// list items count can reach up to a million, thus will crash the app
// Documentation: https://github.com/bvaughn/react-window
const CheckboxDateTree: FC<CheckboxProps> = (props) => {
  const { checked, onToggleSwitch, listItems } = props;
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const _ = require("lodash");
  const listHeight = 32;
  const rowHeights = useRef<{ [key: number]: number }>({}); //will store heights of each items on the list
  const listRef = useRef<VariableSizeList>(null);
  const [pixelHeight, setPixelHeight] = useState(200); //list container height

  const flatListItems = useMemo(
    () => listItems.map((item) => item.raw),
    [listItems]
  );
  const prevList = usePrevious(flatListItems);

  //grouped and nested/tree format of dates
  const groupedDates = useMemo(
    (): GroupedDates[] =>
      _(flatListItems)
        .groupBy((date: string) => moment(date).year())
        .map((years: number[] | string[]) => {
          if (years[0] === FILTER_BLANK_TEXT_DISPLAY) {
            return {
              year: FILTER_IS_EMPTY_OPERATOR.label,
              values: [
                {
                  displayValue: FILTER_IS_EMPTY_OPERATOR.label,
                  raw: FILTER_BLANK_TEXT_DISPLAY,
                },
              ],
              open: false,
              groupedChildren: [],
            };
          } else {
            return {
              year: moment(years[0]).year(),
              values: years.map((year) => ({ displayValue: year, raw: year })),
              open: false,
              groupedChildren: _(years)
                .groupBy((date: string) => moment(date, "YYYY-MM-DD").month())
                .map((months: number[]) => {
                  return {
                    month: moment(months[0]).month() + 1, //month is 0-11
                    values: months.map((month) => ({
                      displayValue: month,
                      raw: month,
                    })),
                    open: false,
                    groupedChildren: _(months)
                      .groupBy((date: string) =>
                        moment(date, "YYYY-MM-DD").date()
                      )
                      .map((dayOfTheMonth: number[]) => {
                        return {
                          day: moment(dayOfTheMonth[0]).date(),
                          values: dayOfTheMonth.map((day) => ({
                            displayValue: day,
                            raw: day,
                          })),
                        };
                      })
                      .value(),
                  };
                })
                .value(),
            };
          }
        })
        .value(),
    [flatListItems]
  );

  const getRowHeight = (index: number) => {
    return rowHeights.current[index] || listHeight;
  };

  //list item component
  // eslint-disable-next-line react/display-name
  const renderRow = memo((props: VirtualizedDateRowList) => {
    const { index, style, data } = props;
    const targetData = data?.groupedDates?.[index];
    const labelId = `checkbox-list-label-${index}`;
    const text = targetData?.year;
    const rowRef = useRef<HTMLDivElement | Record<string, never>>({});

    const checkedRaw = useMemo(
      () => data.checked.map((c) => c.raw),
      [data.checked]
    );

    //year chevron icon is clicked
    const handleParentClick = () => {
      const targetDateOpen = groupedDates[index].open;
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight, !targetDateOpen, -1);
      }
      if (targetDateOpen) {
        // if year is to be collapsed, close all expanded months under it
        groupedDates[index].groupedChildren.forEach((month) => {
          month.open = !targetDateOpen;
        });
      }
      groupedDates[index].open = !targetDateOpen;
    };

    //month chevron icon is clicked
    const handleChildrenClick = (childrenIndex: number) => {
      if (rowRef.current) {
        setRowHeight(
          index,
          rowRef.current.clientHeight,
          !groupedDates[index].groupedChildren[childrenIndex].open,
          childrenIndex
        );
      }
      groupedDates[index].groupedChildren[childrenIndex].open =
        !groupedDates[index].groupedChildren[childrenIndex].open;
    };

    return (
      <Box style={style} key={labelId} ref={rowRef}>
        {/* Year Item  */}
        <ListItem className="year-filter-item" disablePadding>
          <IconButton
            onClick={handleParentClick}
            className={classNames({
              "nulls-checkbox":
                targetData.year.toString() === FILTER_IS_EMPTY_OPERATOR.label,
            })}
          >
            {targetData.year.toString() !== FILTER_IS_EMPTY_OPERATOR.label &&
              (groupedDates[index].open ? <ExpandLess /> : <ExpandMore />)}
          </IconButton>
          <ListItemButton
            className="pl-0"
            onClick={() => {
              const values = targetData.values.filter(
                (val) => !checkedRaw.includes(val.raw)
              );

              if (values.length) {
                data.onToggleSwitch(values);
              } else {
                data.onToggleSwitch(targetData.values);
              }
            }}
            dense
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={targetData.values.every((val) =>
                  checkedRaw.includes(val.raw)
                )}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>
            <ListItemText
              className="grid-filter-text-list"
              id={labelId}
              primary={text}
            />
          </ListItemButton>
        </ListItem>
        {targetData.year.toString() !== FILTER_IS_EMPTY_OPERATOR.label &&
          targetData.groupedChildren.length > 0 &&
          targetData.groupedChildren.map((monthVal, monthIndex) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <Collapse
                in={groupedDates[index].open}
                timeout="auto"
                unmountOnExit
              >
                {/* Month Item  */}
                <ListItem className="month-filter-item" disablePadding>
                  <IconButton onClick={() => handleChildrenClick(monthIndex)}>
                    {groupedDates[index].groupedChildren[monthIndex].open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </IconButton>
                  <ListItemButton
                    onClick={() => {
                      const values = monthVal.values.filter(
                        (val) => !checkedRaw.includes(val.raw)
                      );

                      if (values.length) {
                        data.onToggleSwitch(values);
                      } else {
                        data.onToggleSwitch(monthVal.values);
                      }
                    }}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={monthVal.values.every((val) =>
                          checkedRaw.includes(val.raw)
                        )}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={monthVal.month} />
                  </ListItemButton>
                </ListItem>
                {monthVal.groupedChildren.length > 0 && (
                  <Collapse
                    in={groupedDates[index].groupedChildren[monthIndex].open}
                    timeout="auto"
                    unmountOnExit
                  >
                    {monthVal.groupedChildren.map((dayVal) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <Box>
                          {/* Day Item  */}
                          <ListItem className="day-filter-item" disablePadding>
                            <ListItemButton
                              onClick={() => {
                                data.onToggleSwitch(dayVal.values);
                              }}
                              dense
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={dayVal.values.every((val) =>
                                    checkedRaw.includes(val.raw)
                                  )}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={dayVal.day} />
                            </ListItemButton>
                          </ListItem>
                        </Box>
                      );
                    })}
                  </Collapse>
                )}
              </Collapse>
            );
          })}
      </Box>
    );
  }, areEqual);

  //sets dynamic height of each item based on the number of list to be added under it
  const setRowHeight = useCallback(
    (index: number, size: number, open: boolean, childrenIndex: number) => {
      //called whenever an item height changes
      //according to: https://react-window.vercel.app/#/api/VariableSizeList
      listRef?.current?.resetAfterIndex(0);

      const childrenCount =
        childrenIndex >= 0
          ? groupedDates[index]?.groupedChildren[childrenIndex]?.groupedChildren
              .length
          : groupedDates[index]?.groupedChildren.length + 1;

      if (open) {
        const newHeight =
          childrenIndex >= 0
            ? childrenCount * listHeight + size
            : childrenCount * size;
        rowHeights.current = { ...rowHeights.current, [index]: newHeight };

        if (groupedDates.length < 5) {
          if (childrenIndex >= 0) {
            setPixelHeight(120);
          } else {
            setPixelHeight(80);
          }
        }
      } else {
        const reducedHeight =
          childrenIndex >= 0 ? size - childrenCount * listHeight : listHeight;
        rowHeights.current = { ...rowHeights.current, [index]: reducedHeight };

        if (groupedDates.length < 5) {
          if (childrenIndex >= 0) {
            setPixelHeight(80);
          } else {
            setPixelHeight(getPixelHeight(groupedDates.length));
          }
        }
      }
    },
    [groupedDates, listRef]
  );

  const itemData = useMemo(
    () => ({
      groupedDates,
      checked,
      onToggleSwitch,
    }),
    [checked, onToggleSwitch, groupedDates]
  );

  const getPixelHeight = useCallback((length: number) => {
    switch (length) {
      case 1:
        return 40;
      case 2:
        return 80;
      case 3:
        return 120;
      case 4:
        return 180;
      default:
        return 200;
    }
  }, []);

  //adjust container height according to length of year date
  //triggers: initial mount and search
  useEffect(() => {
    if (groupedDates && groupedDates.length) {
      setPixelHeight(getPixelHeight(groupedDates.length));
    }
  }, [groupedDates]);

  //reset rowHeight on every search
  useEffect(() => {
    if (prevList && prevList !== flatListItems) {
      //called whenever an item height changes
      //according to: https://react-window.vercel.app/#/api/VariableSizeList
      listRef?.current?.resetAfterIndex(0);
      rowHeights.current = {};
    }
  }, [flatListItems]);

  return (
    <div className="checkbox-list-container">
      {groupedDates && (
        <VariableSizeList
          ref={listRef}
          height={pixelHeight}
          width={"100%"}
          itemSize={getRowHeight}
          itemCount={groupedDates.length}
          itemData={itemData}
        >
          {renderRow}
        </VariableSizeList>
      )}
    </div>
  );
};

export default CheckboxDateTree;
