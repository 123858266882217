import { useState } from "react";

import axios, { AxiosError } from "axios";

import { APIErrorResponse } from "../../../types/common/api";
import {
  GetWellCardDataProps,
  GetWellCardDataRequestBody,
} from "../../../types/panels/wellPanel/hooks";
import {
  MapLayer,
  WellCardResponseData,
  WellSystemData,
} from "../../../types/panels/wellPanel/wellPanel";

import config from "../../../configs/appSettings";

import {
  MAP_LAYER,
  WELL_PANEL_TAB,
  WELL_TYPES,
} from "../../../constants/panels/wellPanel/wellInfo";

import useUnitOfMeasure from "../../common/useUnitOfMeasure";

const wellCardUrl = `${config.endpoints.wellService}api/wells/well-card`;

const useGetWellPanelData = () => {
  const [isWellCardDataLoading, setIsWellCardDataLoading] = useState(false);
  const [isWellCardDataError, setIsWellCardDataError] = useState<
    string | undefined
  >(undefined);
  const [wellCardData, setWellCardData] = useState<
    WellSystemData[] | undefined
  >(undefined);
  const [isSurfaceAndBottomEqual, setIsSurfaceAndBottomEqual] =
    useState<boolean>(false);

  const { isMetricOnSearch } = useUnitOfMeasure();

  const getWellCard = async (wellData: GetWellCardDataProps) => {
    try {
      setIsWellCardDataLoading(true);
      const surfaceLayers: MapLayer[] = [
        MAP_LAYER.DYNAMIC_WELL_SPOTS,
        MAP_LAYER.WELL_SPOTS,
      ];
      const isSurfaceLayer = surfaceLayers.includes(wellData.layer);

      const body: GetWellCardDataRequestBody = {
        wellSpotType:
          wellData.layer === MAP_LAYER.WELL_SPOTS ||
          wellData.layer === MAP_LAYER.DYNAMIC_WELL_SPOTS
            ? WELL_TYPES.SURFACE
            : WELL_TYPES.BOTTOM,
        ...(isSurfaceLayer
          ? { parentWellId: wellData.parentWellId }
          : { bottomWellboreId: wellData.bottomWellBoreId }),
        isMetric: isMetricOnSearch,
      };

      const response = await axios.post(wellCardUrl, body);

      if (!response || !("data" in response)) {
        return;
      }

      if (body.wellSpotType === WELL_TYPES.BOTTOM) {
        const surfaceDataBody: GetWellCardDataRequestBody = {
          wellSpotType: WELL_TYPES.SURFACE,
          parentWellId: wellData.parentWellId,
          isMetric: isMetricOnSearch,
        };
        const surfaceData = await axios.post(wellCardUrl, surfaceDataBody);

        if (!surfaceData || !("data" in surfaceData)) {
          return;
        }

        setIsSurfaceAndBottomEqual(surfaceData.data.length <= 1);
      }

      const wellCardDataList: WellSystemData[] = [];

      response.data.forEach((well: WellCardResponseData, index: number) => {
        const wellCardObj: WellSystemData = {
          wellCardData: {
            ...well,
            isDataLoaded: true,
          },
          wellId: well.wellId as number,
          pageNumber: index + 1,
          pageSectionStatus: {
            isCasingSectionOpen: false,
            isCompletionSectionOpen: false,
            isHeaderSectionOpen: true,
            isPerfSectionOpen: false,
            isStatisticsSectionOpen: false,
            isTestSectionOpen: false,
            isProductionPlotSectionOpen: true,
            isGunBarrelSectionOpen: true,
            isWellEconomicsOpen: false,
            isFormationSectionOpen: false,
            isPermitSectionOpen: false,
            isDeclineCurveParametersOpen: false,
            isPressureDataOpen: false,
            isCashflowChartOpen: true,
          },
          activeTab: WELL_PANEL_TAB.WELL_INFO_TAB,
        };

        if (well.hasGunBarrel) {
          wellCardObj["isGunBarrelLoaded"] = !Boolean(well.hasGunBarrel);
        }

        wellCardDataList.push(wellCardObj);
      });

      setWellCardData(wellCardDataList);
    } catch (errorResponse) {
      const err = errorResponse as AxiosError<APIErrorResponse>;
      setIsWellCardDataError(err.message);
    } finally {
      setIsWellCardDataLoading(false);
    }
  };

  return {
    getWellCard,
    isWellCardDataLoading,
    isWellCardDataError,
    wellCardData,
    isSurfaceAndBottomEqual,
  };
};

export default useGetWellPanelData;
