import { FC, useMemo } from "react";

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";

import { Toggle } from "component-library";

import { ColorByPickerProps } from "../../../../types/charts/chartTypeSettings/chartTypeSettings";
import { Attribute } from "../../../../types/common/attributes";

import { DATE, FLOAT, INTEGER } from "../../../../constants/attributes";
import {
  maxValColor,
  minValColor,
} from "../../../../constants/charts/scatterPlotDataFields";

import { useScatterPlotColorByChartData } from "../../../../customHooks/charts/useScatterPlotColorByChartData";

import { formatColorsToRGB } from "../../../../utils/charts/formatter";

import ColorByRange from "./ColorByRange";

const ColorByPicker: FC<ColorByPickerProps> = ({
  chartColorByInfo,
  handleOnSelectChange,
  handleOnBlur,
  handleColorByToggleChange,
  dataList,
  isError,
  fieldToggle,
}) => {
  const { convertMinMaxToString } = useScatterPlotColorByChartData();

  const minMaxVal = useMemo(() => {
    if (chartColorByInfo) {
      return convertMinMaxToString(chartColorByInfo);
    } else {
      return {
        min: null,
        max: null,
      };
    }
  }, [chartColorByInfo?.minMax?.min, chartColorByInfo?.minMax?.min]);

  const colorByAttributeValue = useMemo(() => {
    return (
      dataList.find((data) => data.key === chartColorByInfo?.attributeKey) ??
      null
    );
  }, [chartColorByInfo?.attributeKey]);

  return (
    <Grid container item className="color-by-picker-container">
      <Toggle
        label="Color by"
        checked={chartColorByInfo?.toggle ?? false}
        onChange={handleColorByToggleChange}
        className="custom-primary-icon-color"
      />
      <FormControl error={isError}>
        <Autocomplete
          disablePortal
          disableClearable={colorByAttributeValue !== null}
          options={dataList}
          value={colorByAttributeValue}
          onBlur={handleOnBlur}
          onChange={(e, newValue) => {
            handleOnSelectChange(newValue?.key ?? "");
          }}
          isOptionEqualToValue={(option, value): boolean =>
            (option as Attribute).key === (value as Attribute).key
          }
          disabled={!chartColorByInfo?.toggle}
          PaperComponent={({ children }) => (
            <Paper className="color-by-select-paper">{children}</Paper>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className="color-by-select"
              InputLabelProps={{ shrink: true }}
              label="Attribute"
              placeholder="-Select an option-"
              error={isError}
              size="small"
            />
          )}
          renderOption={(props, option) => {
            return (
              <MenuItem {...props} value={option.key}>
                {option.label}
              </MenuItem>
            );
          }}
        />
        {isError && <FormHelperText>Select an Attribute</FormHelperText>}
      </FormControl>
      {(chartColorByInfo?.attibuteDataType === INTEGER ||
        chartColorByInfo?.attibuteDataType === FLOAT ||
        chartColorByInfo?.attibuteDataType === DATE) &&
        chartColorByInfo.toggle && (
          <ColorByRange
            fieldToggle={fieldToggle}
            minColor={formatColorsToRGB(minValColor)}
            maxColor={formatColorsToRGB(maxValColor)}
            minValue={minMaxVal.min ?? ""}
            maxValue={minMaxVal.max ?? ""}
          />
        )}
    </Grid>
  );
};

export default ColorByPicker;
