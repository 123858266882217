import { Attribute } from "../../../types/common/attributes";

import * as attributes from "../../attributes";
import * as wellPanelAttributes from "../wellPanel/wellPanelAttributes";

export const permitColumns = [
  {
    field: "uwi",
    headerName: "UWI",
    width: 100,
    sortable: false,
  },
  {
    field: "permitNumber",
    headerName: attributes.PERMIT_NUMBER.label,
    width: 120,
    sortable: false,
  },
  {
    field: "permitApprovalDate",
    headerName: attributes.PERMIT_APPROVAL_DATE.label,
    width: 80,
    sortable: false,
  },
  {
    field: "permitPostedDate",
    headerName: attributes.PERMIT_POSTED_DATE.label,
    width: 130,
    sortable: false,
  },
  {
    field: "proposedTotalDepth",
    headerName: "Permit Proposed Depth",
    width: 130,
    sortable: false,
    type: "number",
  },
  {
    field: wellPanelAttributes.PERMIT_FORMATION.key,
    headerName: wellPanelAttributes.PERMIT_FORMATION.label,
    width: 100,
    sortable: false,
  },
  {
    field: "wellPurpose",
    headerName: attributes.PERMIT_PURPOSE.label,
    width: 110,
    sortable: false,
  },
  {
    field: wellPanelAttributes.PERMIT_TYPE_CODE.key,
    headerName: wellPanelAttributes.PERMIT_TYPE_CODE.label,
    width: 110,
    sortable: false,
  },
  {
    field: wellPanelAttributes.WELL_ORIENTATION.key,
    headerName: wellPanelAttributes.WELL_ORIENTATION.label,
    width: 110,
    sortable: false,
  },
  {
    field: "wellName",
    headerName: attributes.WELL_NAME.label,
    width: 110,
    sortable: false,
  },
  {
    field: "wellNumber",
    headerName: attributes.WELL_NUMBER.label,
    width: 110,
    sortable: false,
  },
  {
    field: wellPanelAttributes.PERMIT_SURFACE_LATITUDE.key,
    headerName: wellPanelAttributes.PERMIT_SURFACE_LATITUDE.label,
    width: 130,
    sortable: false,
  },
  {
    field: wellPanelAttributes.PERMIT_SURFACE_LONGITUDE.key,
    headerName: wellPanelAttributes.PERMIT_SURFACE_LONGITUDE.label,
    width: 130,
    sortable: false,
  },
  {
    field: wellPanelAttributes.PERMIT_BOTTOM_HOLE_LAT.key,
    headerName: wellPanelAttributes.PERMIT_BOTTOM_HOLE_LAT.label,
    width: 130,
    sortable: false,
  },
  {
    field: wellPanelAttributes.PERMIT_BOTTOM_HOLE_LONGITUDE.key,
    headerName: wellPanelAttributes.PERMIT_BOTTOM_HOLE_LONGITUDE.label,
    width: 130,
    sortable: false,
  },
];

export const PERMIT_HEADER_SECTION = {
  key: "header",
  displayLabel: "Header",
  urlSlug: "permit-panel",
} as const;

export const PERMIT_OPERATOR_CONTACT_SECTION = {
  key: "operator-contract",
  displayLabel: "Operator Information",
  urlSlug: "permit-panel",
} as const;

export const PERMIT_RELATED_WELLS_SECTION = {
  key: "related-wells",
  displayLabel: "Related Wells",
  urlSlug: "",
} as const;

export const PERMIT_RELATED_PERMITS_SECTION = {
  key: "related-permits",
  displayLabel: "Related Permits",
  urlSlug: "",
} as const;

export const PERMIT_PANEL_SECTION = {
  HEADER_SECTION: PERMIT_HEADER_SECTION,
  OPERATOR_CONTACT_SECTION: PERMIT_OPERATOR_CONTACT_SECTION,
  RELATED_WELLS_SECTION: PERMIT_RELATED_WELLS_SECTION,
  RELATED_PERMITS_SECTION: PERMIT_RELATED_PERMITS_SECTION,
} as const;

export const PERMIT_PANEL_SECTION_KEYS = Object.values(
  PERMIT_PANEL_SECTION
).map((section) => section.key);

export const PERMIT_INFO_HEADER_ATTRIBUTES: {
  [key: string]: Pick<Attribute, "key" | "label">;
} = {
  UWI: { label: "UWI", key: "uwi" },
  PERMIT_NUMBER: { ...attributes.PERMIT_NUMBER, key: "permitNumber" },
  RECORD_TYPE: { label: "Record Type", key: "recordType" },
  POSTED_DATE: { label: "Posted Date", key: "permitPostedDate" },
  APPROVED_DATE: {
    label: "Approved Date",
    key: "permitApprovalDate",
  },
  WELL_TYPE: { ...attributes.WELL_TYPE, key: "wellType" },
  WELL_NAME: { ...attributes.WELL_NAME, key: "wellName" },
  WELL_NUMBER: { ...attributes.WELL_NUMBER, key: "wellNumber" },
  WELL_STATUS: { ...attributes.WELL_STATUS, key: "wellStatus" },
  PERMIT_PURPOSE: { ...attributes.PERMIT_PURPOSE, key: "wellPurpose" },
  PERMIT_TYPE: { ...attributes.PERMIT_TYPE, key: "permitType" },
  SURFACE_LATITUDE: {
    key: "surfaceLatitude",
    label: "Surface Lat WGS84",
  },
  SURFACE_LONGTITUDE: {
    key: "surfaceLongitude",
    label: "Surface Lon WGS84",
  },
  BOTTOM_LATITUDE: {
    key: "bottomLatitude",
    label: "Bottom Lat WGS84",
  },
  BOTTOM_LONGTITUDE: {
    key: "bottomLongitude",
    label: "Bottom Lon WGS84",
  },
  PROPOSED_DEPTH: {
    ...attributes.PERMIT_TOTAL_DEPTH,
    key: "proposedTotalDepth",
  },
  SLANT: { ...attributes.SLANT, key: "slant" },
  PERMIT_HAS_H2S_GAS: { ...attributes.PERMIT_HAS_H2S, key: "h2sFlag" },
  FORMATION: {
    label: "Formation",
    key: "formation",
  },
};

export const PERMIT_OPERATOR_CONTACT_INFO: {
  [key: string]: Pick<Attribute, "key" | "label">;
} = {
  OPERATOR_NAME: { ...attributes.ULTIMATE_OWNER, key: "ultimateOwner" },
  OPERATOR_CONTACT: {
    label: "Contact",
    key: "operatorContact",
  },
  PHONE_NUMBER: {
    label: "Phone Number",
    key: "operatorPhoneNumber",
  },
  ADDRESS: {
    label: "Address",
    key: "operatorAddress",
  },
  CITY: {
    label: "City",
    key: "operatorCity",
  },
  STATE: {
    label: "State",
    key: "operatorState",
  },
  ZIP_CODE: {
    label: "Zip Code",
    key: "operatorZipcode",
  },
};

export const PERMIT_RELATED_WELLS_INFO: {
  [key: string]: Pick<Attribute, "key" | "label">;
} = {
  NO_WELLS: {
    key: "noOfWellInSystem",
    label: "No. of Wells in System",
  },
  SPUD_DATE: { ...attributes.SPUD_DATE, key: "spudDate" },
  UWI: {
    key: "uwi",
    label: "UWI",
  },
  WELL_NAME: { ...attributes.WELL_NAME, key: "wellName" },
  WELL_NUMBER: { ...attributes.WELL_NUMBER, key: "wellNumber" },
};
