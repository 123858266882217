import { FC } from "react";

interface ChartExportTitleProps {
  chart: string;
  uwi?: string;
}

const ChartExportImageTitle: FC<ChartExportTitleProps> = ({ chart, uwi }) => {
  return (
    <div className="chart-name">
      <div className="chart-type">{`${chart} ${uwi ? "_" + uwi : ""}`}</div>
    </div>
  );
};

export default ChartExportImageTitle;
