import { FormControlLabel, Switch, Typography } from "@mui/material";

import { ForecastCheckboxProps } from "../../../../types/charts/chartTypeSettings/chartTypeSettings";

const ForecastCheckbox = ({
  showForecast,
  handleForecastSelectionChange,
}: ForecastCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={showForecast}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleForecastSelectionChange(e);
          }}
          className="custom-primary-icon-color"
        />
      }
      label={
        <Typography sx={{ fontSize: "14px", color: "white" }}>
          Include Forecast
        </Typography>
      }
    />
  );
};

export default ForecastCheckbox;
