import { useEffect, useMemo, useState } from "react";

import { WellExportCategories } from "../../../../types/panels/wellPanel/exportWellPanel";
import { WellPanelInfo } from "../../../../types/panels/wellPanel/wellPanel";

import { CHART_MODE_VIEW } from "../../../../constants/charts/chartModeView";
import { GUN_BARREL, PRODUCTION_PLOT } from "../../../../constants/constants";
import {
  CASHFLOW_CHART_SECTION,
  GUN_BARREL_SECTION,
  PRODUCTION_PLOT_SECTION,
  WELL_INFO_SORT_LIST,
} from "../../../../constants/panels/wellPanel/wellInfo";
import {
  WELL_CHARTS_EXPORT_CATEGORY,
  WELL_EXPORT_ALL,
  WELL_INFORMATION_ARRAY_LIST,
  WELL_INFORMATION_EXPORT_CATEGORY,
  WELL_PANEL_CHARTS_ARRAY_LIST,
} from "../../../../constants/panels/wellPanel/wellPanelExport";

import useExportPanelStore from "../../../../store/exportPanel/exportPanelStore";
import usePanelsStore from "../../../../store/panels/panelsStore";
import useStore from "../../../../store/useStore";

import useExportImage from "../../../exports/useExportImage";

const useExportWellPanel = () => {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const openedPanelAvailableSections = useExportPanelStore(
    (state) => state.openedPanelAvailableSections
  );
  const updateGunBarrelData = useExportPanelStore(
    (state) => state.updateGunBarrelData
  );
  const toggleExportPlotMessage = usePanelsStore(
    (state) => state.toggleExportPlotMessage
  );
  const exportChart = useStore((state) => state.exportChart);
  const updateExportChart = useStore((state) => state.updateExportChart);

  const wellInformationSectionList = useMemo(() => {
    return openedPanelAvailableSections
      .filter((section) => WELL_INFORMATION_ARRAY_LIST.includes(section))
      .sort(
        (a, b) =>
          WELL_INFO_SORT_LIST.indexOf(a) - WELL_INFO_SORT_LIST.indexOf(b)
      );
  }, [openedPanelAvailableSections]);

  const plotChartSectionList = useMemo(() => {
    return openedPanelAvailableSections.filter((section) =>
      WELL_PANEL_CHARTS_ARRAY_LIST.includes(section)
    );
  }, [openedPanelAvailableSections]);

  const { exportImage } = useExportImage();

  const handleCheckedItem = (item: string) => {
    if (!checkedItems.includes(item)) {
      setCheckedItems((prevItems) => [...prevItems, item]);
    } else {
      const filteredItems = checkedItems.filter((checked) => checked !== item);
      setCheckedItems(filteredItems);
    }
  };

  const handleCheckedCategoryItem = (
    category: WellExportCategories,
    checked: boolean
  ) => {
    if (category === WELL_INFORMATION_EXPORT_CATEGORY) {
      if (checked) {
        setCheckedItems((prev) => [...prev, ...wellInformationSectionList]);
      } else {
        const filteredItems = checkedItems.filter(
          (checked) => !wellInformationSectionList.includes(checked)
        );
        setCheckedItems(filteredItems);
      }
    } else if (category === WELL_CHARTS_EXPORT_CATEGORY) {
      //for export to image select condition
      if (checked) {
        setCheckedItems((prev) => [...prev, ...plotChartSectionList]);
      } else {
        const filteredItems = checkedItems.filter(
          (checked) => !plotChartSectionList.includes(checked)
        );
        setCheckedItems(filteredItems);
      }
    } else if (category === WELL_EXPORT_ALL) {
      if (checked) {
        setCheckedItems(openedPanelAvailableSections);
      } else {
        const filteredItems = checkedItems.filter(
          (checked) => !openedPanelAvailableSections.includes(checked)
        );
        setCheckedItems(filteredItems);
      }
    }
  };

  useEffect(() => {
    setCheckedItems(openedPanelAvailableSections);
  }, [openedPanelAvailableSections]);

  const handleExportImagePlots = (
    uwi: string,
    gunBarrelRef: React.RefObject<HTMLDivElement | HTMLInputElement>,
    productionRef: React.RefObject<HTMLDivElement | HTMLInputElement>,
    cashflowRef: React.RefObject<HTMLDivElement | HTMLInputElement>
  ) => {
    toggleExportPlotMessage(true);

    if (
      checkedItems.includes(GUN_BARREL_SECTION.key) &&
      gunBarrelRef?.current
    ) {
      exportImage(uwi, `${GUN_BARREL}_${uwi}`, GUN_BARREL, gunBarrelRef);
    }
    if (
      checkedItems.includes(PRODUCTION_PLOT_SECTION.key) &&
      productionRef?.current
    ) {
      exportImage(
        uwi,
        `${PRODUCTION_PLOT}_${uwi}`,
        PRODUCTION_PLOT,
        productionRef
      );
    }

    if (
      checkedItems.includes(CASHFLOW_CHART_SECTION.key) &&
      cashflowRef.current
    ) {
      exportImage(
        uwi,
        `${CASHFLOW_CHART_SECTION.displayLabel}_${uwi}`,
        CASHFLOW_CHART_SECTION.displayLabel,
        cashflowRef
      );
    }
  };

  const updateExportImage = (wellPanelInfo: WellPanelInfo, uwi: string) => {
    if (wellPanelInfo.isActive) {
      const getActivePage = wellPanelInfo.activePage;
      const getChartExports: any = [];
      wellPanelInfo?.wellSystemData?.forEach((wells) => {
        if (wells.pageNumber === getActivePage) {
          if (wells.gunBarrelData) {
            getChartExports.push({
              chartType: GUN_BARREL,
              chartId: uwi,
              chartMode: CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART,
            });
          }
          if (wells.productionData) {
            getChartExports.push({
              chartType: PRODUCTION_PLOT_SECTION.key,
              chartId: uwi,
              chartMode: CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART,
            });
          }
          updateExportChart(getChartExports);
          updateGunBarrelData(wells.gunBarrelData);
        }
      });
    }
  };

  const handleSingleChartImageExport = (
    uwi: string,
    chartType: string,
    ref: React.RefObject<HTMLDivElement | HTMLInputElement>
  ) => {
    toggleExportPlotMessage(true);
    exportImage(uwi, `${chartType}_${uwi}`, chartType, ref);
  };

  return {
    checkedItems,
    wellInformationSectionList,
    plotChartSectionList,
    handleCheckedItem,
    handleExportImagePlots,
    handleCheckedCategoryItem,
    handleSingleChartImageExport,
    updateExportImage,
  };
};

export default useExportWellPanel;
