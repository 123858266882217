import FileSaver from "file-saver";
import { Data } from "plotly.js";
import XLSX from "sheetjs-style";

import { PressureDataWellPanelExport } from "../../types/panels/wellPanel/pressureDataType";
import {
  WellData,
  WellSystemData,
} from "../../types/panels/wellPanel/wellPanel";

import {
  CASH_FLOW_CHART_INFO_KEY,
  WELL_PANEL_SECTION,
} from "../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../store/panels/panelsStore";

import { formatCashflowExport } from "../../utils/exports/cashflowExport";

import useSortWellPanelData from "./useSortWellPanelData";

const useExportWellPanelData = () => {
  const toggleExportMessage = usePanelsStore(
    (state) => state.toggleExportMessage
  );
  const {
    sortCasingData,
    sortPerfData,
    sortTestingData,
    sortPressureData,
    sortFormationsData,
    sortPermitsData,
    sortWellPanelContent,
  } = useSortWellPanelData();

  const exportWellPanelExcel = (
    wellData: WellSystemData[],
    exportList: string[]
  ) => {
    const allWellCardData: WellData[] = [];
    const allPerfData: any[] = [];
    const allTestData: any[] = [];
    const allCasingData: any[] = [];
    const allPermitsData: any[] = [];
    const allFormationsData: any[] = [];
    const allPressureData: PressureDataWellPanelExport[][] = [];
    const cashFlowChart: Data[] = [];

    wellData.forEach((item: WellSystemData) => {
      if (
        item.wellCardData &&
        (exportList.includes(WELL_PANEL_SECTION.HEADER_SECTION.key) ||
          exportList.includes(WELL_PANEL_SECTION.STATISTICS_SECTION.key) ||
          exportList.includes(
            WELL_PANEL_SECTION.DECLINE_CURVE_PARAMETERS_SECTION.key
          ) ||
          exportList.includes(WELL_PANEL_SECTION.COMPLETION_SECTION.key))
      ) {
        allWellCardData.push(item.wellCardData);
      }
      if (
        item.perforationData &&
        exportList.includes(WELL_PANEL_SECTION.PERFORATION_SECTION.key)
      ) {
        const perfData = item.perforationData.map((perf) => ({
          ...perf,
          uwi: item.wellCardData.uwi,
        }));
        allPerfData.push(perfData);
      }

      if (
        item.testingData &&
        exportList.includes(WELL_PANEL_SECTION.TEST_SECTION.key)
      ) {
        const testData = item.testingData.map((test) => ({
          ...test,
          uwi: item.wellCardData.uwi,
        }));
        allTestData.push(testData);
      }

      if (
        item.pressureData &&
        exportList.includes(WELL_PANEL_SECTION.PRESSURE_DATA_SECTION.key)
      ) {
        const pressureData: PressureDataWellPanelExport[] =
          item.pressureData.map((pressure) => {
            const newPressureData: PressureDataWellPanelExport = {
              uwi: item.wellCardData.uwi as string,
              TestType: pressure.TestType,
              DSTNo: pressure.DSTNo,
              Formation: pressure.Formation,
              TgsLandingZone: pressure.TgsLandingZone,
              Temperature: pressure.Temperature,
              Top: pressure.Top,
              Bottom: pressure.Bottom,
              FirstFlow: pressure.FirstFlow,
              FirstFlowShutIn: pressure.FirstFlowShutIn,
              SecondFlow: pressure.SecondFlow,
              SecondFlowShutIn: pressure.SecondFlowShutIn,
              RecoveryRemarks: pressure.RecoveryRemarks,
            };
            return newPressureData;
          });
        allPressureData.push(pressureData);
      }

      if (
        item.casingData &&
        exportList.includes(WELL_PANEL_SECTION.CASING_SECTION.key)
      ) {
        const casingData = item.casingData.map((casing) => ({
          ...casing,
          uwi: item.wellCardData.uwi,
        }));
        allCasingData.push(casingData);
      }
      if (
        item.permitsData &&
        exportList.includes(WELL_PANEL_SECTION.PERMIT_SECTION.key)
      ) {
        const permitData = item.permitsData.permit.map((permit) => ({
          ...permit,
          uwi: item.wellCardData.uwi,
        }));
        allPermitsData.push(permitData);
      }
      if (
        item.formationData &&
        exportList.includes(WELL_PANEL_SECTION.FORMATION_SECTION.key)
      ) {
        const formationData = item.formationData.formation.map((formation) => ({
          ...formation,
          uwi: item.wellCardData.uwi,
        }));
        allFormationsData.push(formationData);
      }

      if (item.cashflowData && exportList.includes(CASH_FLOW_CHART_INFO_KEY)) {
        const cashflowExportData = formatCashflowExport(
          item.cashflowData.trace
        );

        cashFlowChart.push(...cashflowExportData);
      }
    });

    const { header, statistics, declineCurveParam, completion } =
      sortWellPanelContent(allWellCardData, exportList);
    const perforation = sortPerfData(allPerfData);
    const testing = sortTestingData(allTestData);
    const pressure = sortPressureData(allPressureData);
    const casing = sortCasingData(allCasingData);
    const formation = sortFormationsData(allFormationsData);
    const permit = sortPermitsData(allPermitsData);

    exportToExcelFile(
      exportList,
      header,
      statistics,
      declineCurveParam,
      completion,
      perforation,
      testing,
      pressure,
      casing,
      permit,
      formation,
      cashFlowChart
    );
  };

  const exportToExcelFile = (
    exportList: string[],
    headerData: any[],
    statisticsData: any[],
    declineCurveParamData: any[],
    completionData: any[],
    perforationData: any[],
    testData: any[],
    pressureData: { [key: string]: string }[],
    casingData: any[],
    permitsData: any[],
    formationsData: any[],
    cashflowData: Data[]
  ) => {
    const fileType = "";
    const fileExtension = ".xlsx";
    const dateTime = new Date();
    const yyyy = dateTime.getFullYear();
    let mm = dateTime.getMonth() + 1; // Months start at 0!
    let dd = dateTime.getDate();
    const hr = dateTime.getHours();
    const min = dateTime.getMinutes();

    if (dd < 10) dd = 0 + dd;
    if (mm < 10) mm = 0 + mm;

    const formattedToday = dd.toString() + mm.toString() + yyyy + hr + min;
    const fileName = "WellCard" + "_" + formattedToday;

    const wb = XLSX.utils.book_new();

    if (headerData.length && exportList.includes("header")) {
      const wsHeader = XLSX.utils.json_to_sheet(headerData);
      XLSX.utils.book_append_sheet(wb, wsHeader, "Header");
    }

    if (statisticsData.length && exportList.includes("statistics")) {
      const wsStatistics = XLSX.utils.json_to_sheet(statisticsData);
      XLSX.utils.book_append_sheet(wb, wsStatistics, "Statistics");
    }

    if (
      declineCurveParamData.length &&
      exportList.includes("declineCurveParameters")
    ) {
      const wsDeclineCurveParam = XLSX.utils.json_to_sheet(
        declineCurveParamData
      );
      XLSX.utils.book_append_sheet(
        wb,
        wsDeclineCurveParam,
        "Decline Curve Parameters"
      );
    }

    if (completionData.length && exportList.includes("completion")) {
      const wsCompletion = XLSX.utils.json_to_sheet(completionData);
      XLSX.utils.book_append_sheet(wb, wsCompletion, "Completions");
    }

    if (perforationData.length && exportList.includes("perforation")) {
      const wsPerforation = XLSX.utils.json_to_sheet(perforationData);
      XLSX.utils.book_append_sheet(wb, wsPerforation, "Perforations");
    }
    if (testData.length && exportList.includes("test")) {
      const wsTest = XLSX.utils.json_to_sheet(testData);
      XLSX.utils.book_append_sheet(wb, wsTest, "Tests");
    }

    if (pressureData.length && exportList.includes("pressureData")) {
      const wsPressureData = XLSX.utils.json_to_sheet(pressureData);
      XLSX.utils.book_append_sheet(wb, wsPressureData, "Pressure Data");
    }

    if (casingData.length && exportList.includes("casing")) {
      const wsCasing = XLSX.utils.json_to_sheet(casingData);
      XLSX.utils.book_append_sheet(wb, wsCasing, "Casings");
    }

    if (permitsData.length && exportList.includes("permit")) {
      const wsPermits = XLSX.utils.json_to_sheet(permitsData);
      XLSX.utils.book_append_sheet(wb, wsPermits, "Permits");
    }
    if (formationsData.length && exportList.includes("formation")) {
      const wsFormations = XLSX.utils.json_to_sheet(formationsData);
      XLSX.utils.book_append_sheet(wb, wsFormations, "Formations");
    }

    if (cashflowData.length && exportList.includes(CASH_FLOW_CHART_INFO_KEY)) {
      const wsFormations = XLSX.utils.json_to_sheet(cashflowData);
      XLSX.utils.book_append_sheet(wb, wsFormations, "Cashflow");
    }

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataFile = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataFile, fileName + fileExtension);

    toggleExportMessage(true);

    setTimeout(() => {
      toggleExportMessage(false);
    }, 3000);
  };

  return { exportWellPanelExcel };
};

export default useExportWellPanelData;
