import { useMemo, useState } from "react";

import {
  DialogActions,
  DialogContent,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";

import { Button } from "component-library";

import { CHART_GROUP_BY_TYPE } from "../../../constants/charts/charts";
import { CHARTS_FIELDS_MAPPER } from "../../../constants/constants";

import useChartStore from "../../../store/chart/chartStore";

import { hasEmoji } from "../../../utils/charts/validator";

import AggregatePicker from "./formPickers/AggregatePicker";
import DailyValuesCheckbox from "./formPickers/DailyValuesCheckbox";
import DataFieldsPicker from "./formPickers/DataFieldsPicker";
import ForecastCheckbox from "./formPickers/ForecastCheckbox";
import NormalizedByLateralLengthCheckbox from "./formPickers/NormalizedByLateralLengthCheckbox";
import ScalePicker from "./formPickers/ScalePicker";

const TypeCurveConfig = (props) => {
  const [title, setTitle] = useState(
    props?.data?.title === ""
      ? props?.data?.chartType + "_" + props?.data?.chartId
      : props?.data?.title
  );
  const chartDataList = useChartStore((state) => state.chartData);

  const aggregatesSelection = useMemo(
    () => [
      {
        id: 1,
        value: [CHARTS_FIELDS_MAPPER[props?.data?.chartType]],
        name: CHART_GROUP_BY_TYPE.DEFAULT, //NOTE: Should use "All" when calling the API
        displayName: "All Wells",
      },
      {
        id: 2,
        value: [CHARTS_FIELDS_MAPPER[props?.data?.chartType]],
        name: CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS, //TO DO: Check API Parameter for individual wells
        displayName: "Individual Wells",
      },
      {
        id: 3,
        value: [CHARTS_FIELDS_MAPPER[props?.data?.chartType]],
        name: "ATTRIBUTES",
        displayName: "Group Wells By Attribute",
      },
    ],
    [props?.data?.chartType]
  );

  const [aggregate, setAggregate] = useState(
    props?.data?.groupBy != CHART_GROUP_BY_TYPE.DEFAULT &&
      props?.data?.groupBy != CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS
      ? "ATTRIBUTES"
      : props?.data?.groupBy
  );
  const [selectedAttr, setSelectedAttr] = useState(
    props?.data?.groupBy === CHART_GROUP_BY_TYPE.DEFAULT ||
      props?.data?.groupBy === CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS
      ? ""
      : props?.data?.groupBy
  );

  const [isInputAggregateError, setIsInputAggregateError] = useState(false);
  const [inputAggregateValidationMessage, setInputAggregateValidationMessage] =
    useState("");

  const [isInputTitleError, setIsInputTitleError] = useState(false);
  const [inputTitleValidationMessage, setInputTitleValidationMessage] =
    useState("");

  const [selectedScale, setSelectedScale] = useState(
    props?.data?.chartYAxisScaling
  );

  const [selectedDataFields, setSelectedDataFields] = useState(
    props?.data?.chartDisplayedDataFields
  );

  const [showDailyValues, setShowDailyValues] = useState(
    props?.data?.chartShowDailyValues ?? false
  );

  const [showByNormalizedLateralLength, setShowByNormalizedLateralLength] =
    useState(props?.data?.chartShowByNormalizedLateralLength ?? false);

  const [showForecast, setShowForecast] = useState(
    props?.data?.chartShowForecast ?? false
  );

  const handleOnSaveClick = () => {
    let chartConfigAggregate = "";
    let isAggregateFormError = false;

    //Check if the aggregate value is ATTRIBUTE
    //IF true, then set chartConfigAggregate to selectedAttr
    if (aggregate === aggregatesSelection[2].name) {
      if (selectedAttr) {
        chartConfigAggregate = selectedAttr;
      } else {
        setIsInputAggregateError(true);
        setInputAggregateValidationMessage("Invalid Aggregate Selection");
        isAggregateFormError = true;
      }
    } else {
      chartConfigAggregate = aggregate;
    }

    if (!isAggregateFormError) {
      props.handleSaveSettings(
        title,
        chartConfigAggregate,
        showForecast,
        showDailyValues,
        showByNormalizedLateralLength,
        selectedScale,
        selectedDataFields,
        props?.data?.chartId
      );
    }
  };

  const isTitleExisting = () => {
    return (
      chartDataList.filter(
        (chart) =>
          (chart.title === title ||
            chart.chartType + "_" + chart.chartId === title) &&
          chart.chartId !== props?.data?.chartId
      ).length > 0
    );
  };

  const handleTitleOnBlur = () => {
    if (!title) {
      setInputTitleValidationMessage("Invalid Chart Title");
      setIsInputTitleError(true);
    } else {
      if (isTitleExisting()) {
        setInputTitleValidationMessage("Duplicated Chart Title");
        setIsInputTitleError(true);
      }

      if (hasEmoji(title)) {
        setInputTitleValidationMessage("invalid Chart Title");
        setIsInputTitleError(true);
      }
    }
  };

  const handleTitleOnChange = (e) => {
    if (isInputTitleError) {
      setIsInputTitleError(false);
      setInputTitleValidationMessage("");
    }

    setTitle(e.target.value);
  };

  const handleOnAggregateTypeChange = (e) => {
    if (isInputAggregateError) {
      setIsInputAggregateError(false);
      setInputAggregateValidationMessage("");
    }
    setAggregate(e.target.value);
  };

  const handleOnGroupByAttributeChange = (e) => {
    setAggregate(aggregatesSelection[2].name); //set aggregate to ATTRIBUTES for the radio button to be selected
    setSelectedAttr(e.target.value);
  };

  const handleOnGroupBySelectorOpen = () => {
    if (isInputAggregateError) {
      setIsInputAggregateError(false);
      setInputAggregateValidationMessage("");
    }
    setAggregate(aggregatesSelection[2].name); //set aggregate to ATTRIBUTES for the radio button to be selected
  };

  const handleOnScaleSelectionChange = (e) => {
    setSelectedScale(e.target.value);
  };

  const handleDataFieldSelection = (dataFieldsList) => {
    setSelectedDataFields(dataFieldsList);
  };

  const handleDailyValuesSelectionChange = (e) => {
    setShowDailyValues(e.target.checked);
  };

  const handleNormalizedLateralLengthSelectionChange = (e) => {
    setShowByNormalizedLateralLength(e.target.checked);
  };

  const handleForecastSelectionChange = (e) => {
    setShowForecast(e.target.checked);
  };

  return (
    <>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="chart-title"
              label="Title"
              size="small"
              className="chart-settings-text"
              value={title}
              error={isInputTitleError}
              helperText={isInputTitleError ? inputTitleValidationMessage : ""}
              onChange={handleTitleOnChange}
              onBlur={handleTitleOnBlur}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <AggregatePicker
              isInputAggregateError={isInputAggregateError}
              aggregate={aggregate}
              handleOnAggregateTypeChange={handleOnAggregateTypeChange}
              aggregatesSelection={aggregatesSelection}
              handleOnGroupByAttributeChange={handleOnGroupByAttributeChange}
              handleOnGroupBySelectorOpen={handleOnGroupBySelectorOpen}
              selectedAttr={selectedAttr}
              inputAggregateValidationMessage={inputAggregateValidationMessage}
            />
          </Grid>
          <Grid className="line-divider" container item></Grid>
          <Grid container item xs={12} spacing={3} className="forecast-wrapper">
            <Grid item xs={12}>
              <FormLabel id="aggregate-label">Visualization options</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <ForecastCheckbox
                handleForecastSelectionChange={handleForecastSelectionChange}
                showForecast={showForecast}
              />
            </Grid>
            <Grid item xs={12}>
              <DailyValuesCheckbox
                handleDailyValuesSelectionChange={
                  handleDailyValuesSelectionChange
                }
                showDailyValues={showDailyValues}
              />
            </Grid>
            <Grid item xs={12}>
              <NormalizedByLateralLengthCheckbox
                handleChange={handleNormalizedLateralLengthSelectionChange}
                chartShowByNormalizedLateralLength={
                  showByNormalizedLateralLength
                }
              />
            </Grid>
          </Grid>

          <Grid className="line-divider" container item></Grid>

          <Grid
            xs={12}
            spacing={3}
            className="align-items-center"
            container
            item
          >
            <ScalePicker
              handleOnScaleSelectionChange={handleOnScaleSelectionChange}
              selectedScale={selectedScale}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <DataFieldsPicker
              onDataFieldsSelection={handleDataFieldSelection}
              selectedDataFields={selectedDataFields}
              aggregate={aggregate}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleClose}
          type="secondary"
          iconLeft="chevronLeft"
          text="Cancel"
          className="chart-cancel-button"
        />
        <Button
          onClick={handleOnSaveClick}
          disabled={
            isInputTitleError ||
            isInputAggregateError ||
            !selectedDataFields?.length
          }
          type="primary"
          iconLeft="save"
          text="Save Settings"
        />
      </DialogActions>
    </>
  );
};
export default TypeCurveConfig;
