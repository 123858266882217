import { Dash } from "plotly.js";

import {
  ChartRange,
  ITraceData,
} from "../../../types/charts/chartType/chartType";

import {
  OPERATING_COST_BAR_DATA,
  REVENUE_BAR_DATA,
} from "../../../constants/charts/cashFlowChart";
import { mmmYYYYTickFormat } from "../../../constants/charts/charts";

import { getLargestSignificantNumber } from "../../helper";
import { transformToCurrencyTick } from "../formatter";

export const config = {
  responsive: true,
  displayModeBar: false,
  edits: {
    legendPosition: true,
  },
};

export const generalLayout = (isForFullScreenView: boolean) => {
  return {
    autosize: true,
    paper_bgcolor: !isForFullScreenView ? "rgba(0,0,0,0)" : "#272727",
    plot_bgcolor: !isForFullScreenView ? "rgba(0,0,0,0)" : "#272727",
    showlegend: true,
  };
};

export const marginLayout = {
  t: 55,
  b: 40,
  l: 50,
  r: 30,
};

export const fontLayout = {
  size: 12,
  family: "Roboto, Arial",
};

export const legendLayout = (
  range?: ChartRange,
  isForFullScreenView?: boolean
) => {
  return {
    ...(range?.xLegend && {
      x: range?.xLegend,
    }),
    ...(range?.yLegend && {
      y: range?.yLegend,
    }),
    font: {
      color: "#838383",
      size: isForFullScreenView ? 18 : 12,
    },
  };
};

export const xAxisLayout = (
  range: ChartRange | undefined,
  shouldFormatXTicks: boolean,
  isForFullScreenView?: boolean
) => {
  const isValid = (currentValue: number | undefined | null) =>
    currentValue !== undefined && currentValue !== null;
  const hasRange = range?.xRange && (range?.xRange as []).every(isValid);
  return {
    title: {
      text: "Date",
      font: {
        family: "Roboto, Arial",
        color: "rgba(117, 117, 117, 1)",
        size: isForFullScreenView ? 18 : 12,
      },
      standoff: 10,
    },
    ...(isForFullScreenView && {
      tickfont: { size: 16 },
    }),
    automargin: true,
    color: "rgba(255, 255, 255, 0.7)",
    linecolor: "rgba(117, 117, 117, 1)",
    griddash: "dot" as Dash,
    gridcolor: "rgba(117, 117, 117, 1)",
    ...(hasRange
      ? {
          range: range.xRange,
          autorange: false,
        }
      : { autorange: true }),
    tickformat: mmmYYYYTickFormat,
    tickangle: -45,
    ...(shouldFormatXTicks && { dtick: "M1" }),
  };
};

export const yAxisLayout = (
  range: ChartRange | undefined,
  tick: number[],
  isForFullScreenView?: boolean
) => {
  const gridDash: Dash = "dot";
  const mode: "auto" | "linear" | "array" = "array";
  const isValid = (currentValue: number | undefined | null) =>
    currentValue !== undefined && currentValue !== null;
  const hasRange = range?.yRange && (range?.yRange as []).every(isValid);

  return {
    tickmode: mode, // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
    tickvals: tick,
    ticktext: tick?.map((tickData) => transformToCurrencyTick(tickData)),
    ...(isForFullScreenView && {
      tickfont: {
        size: 16,
      },
    }),
    ...(hasRange
      ? {
          range: range.yRange,
          autorange: false,
        }
      : { autorange: true }),
    hoverformat: ",",
    automargin: !isForFullScreenView,
    title: {
      text: "",
      font: {
        family: "Roboto, Arial",
        color: "rgba(117, 117, 117, 1)",
        size: isForFullScreenView ? 18 : 12,
      },
      standoff: isForFullScreenView ? 20 : 0,
    },
    size: 8,
    showline: true,
    color: "rgba(255, 255, 255, 0.7)",
    gridcolor: "rgba(117, 117, 117, 1)",
    linecolor: "rgba(117, 117, 117, 1)",
    griddash: gridDash,
  };
};

export const formatChartCustomTicks = (chartData: ITraceData[]) => {
  try {
    let maxRevenueValue = 0;
    let minOpCostValue = 0;
    chartData.forEach((data) => {
      if (data.id === REVENUE_BAR_DATA.id) {
        const maxVal = Math.max(...Object.values({ ...data.y }));
        maxRevenueValue =
          Math.round(maxVal) !== 0 ? Math.round(maxVal) : maxVal;
      } else if (data.id === OPERATING_COST_BAR_DATA.id) {
        const minVal = Math.min(...Object.values({ ...data.y }));
        minOpCostValue = Math.round(minVal) !== 0 ? Math.round(minVal) : minVal;
      }
    });

    const posMinOpCostValue = Math.abs(minOpCostValue);
    const largestSignificantNumber = getLargestSignificantNumber(
      maxRevenueValue + posMinOpCostValue
    );

    const newYData: number[] = [];
    const tickDivider = 5;
    const incrementer = Math.round(largestSignificantNumber / tickDivider);
    const offset = incrementer * -2;
    const initialVal =
      Math.floor(posMinOpCostValue / incrementer) * incrementer * -1;
    let currentTickVal = initialVal + offset;

    // generated 10 ticks to have an extra ticks on top and bottom
    for (let i = 0; i < 10; i++) {
      newYData.push(currentTickVal);
      currentTickVal += incrementer;
    }
    return newYData;
  } catch (error) {
    console.debug(error);
    return [-Infinity, -Infinity, -Infinity];
  }
};
