import {
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { scalingList } from "../../../../constants/charts/multipleAxisConfig";

const ScalePicker = (props) => {
  return (
    <>
      <Grid xs={6} item>
        <FormLabel id="scale-label" className="chart-settings-text">
          Y Axis Scale
        </FormLabel>
      </Grid>
      <Grid xs={6} item>
        <FormControl fullWidth>
          <InputLabel id="scale-select-label" variant="outlined" shrink>
            Scale
          </InputLabel>
          <Select
            id="scale-select"
            label="Scale"
            value={props.selectedScale}
            onChange={props.handleOnScaleSelectionChange}
          >
            {scalingList.map((scale, index) => {
              return (
                <MenuItem key={index} value={scale.name}>
                  {scale.displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default ScalePicker;
