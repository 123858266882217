import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";

import { Layout } from "plotly.js";

import {
  GunBarrelData,
  GunBarrelLayout,
  GunBarrelOriginalData,
  GunBarrelProps,
} from "../../../types/panels/wellPanel/gunBarrelType";

import { GUN_BARREL } from "../../../constants/constants";

import useGunBarrelEventHandler from "../../../customHooks/charts/useGunBarrelEventHandler";
import { useLoadGunBarrel } from "../../../customHooks/charts/useLoadGunBarrel";

import Loading from "../../common/Loading";
import ChartExportImageChartLogo from "../../panels/wellPanel/common/export/ChartExportImageChartLogo";
import ChartExportImageTitle from "../../panels/wellPanel/common/export/ChartExportImageTitle";

const GunBarrelTab = ({
  gunBarrelData,
  uwiNumber,
  chartExport,
}: GunBarrelProps) => {
  const initialData = useRef<GunBarrelData>();
  const initialLayout = useRef<Partial<Layout>>();

  const [state, setState] = useState<GunBarrelLayout>({
    data: [],
    layout: undefined,
    revision: 0,
  });

  const { handleOnUnHover, handleOnHover } = useGunBarrelEventHandler();
  const { updateGunbarrelChart } = useLoadGunBarrel();

  const originalData: GunBarrelOriginalData = {
    data: initialData,
    layout: initialLayout,
  };

  useEffect(() => {
    updateGunbarrelChart(gunBarrelData, setState, originalData, chartExport);
  }, [gunBarrelData, uwiNumber]);

  if (!originalData.data.current && chartExport) return null;

  return (
    <>
      {originalData.data.current && state.layout ? (
        <div className="gun-barrel-container well-panel-chart-container">
          {chartExport && (
            <ChartExportImageTitle chart={GUN_BARREL} uwi={uwiNumber} />
          )}
          <Plot
            config={{ responsive: true, displayModeBar: false }}
            onHover={(e) =>
              handleOnHover({ e, setState, initialData, initialLayout })
            }
            revision={state.revision}
            onUnhover={(e) =>
              handleOnUnHover({ e, setState, initialData, initialLayout })
            }
            data={state.data}
            layout={state.layout}
          />
          {chartExport && <ChartExportImageChartLogo />}
        </div>
      ) : (
        <div className="flexContainer-Spinner">
          <Loading />
        </div>
      )}
    </>
  );
};

export default GunBarrelTab;
