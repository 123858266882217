import { FC } from "react";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { ScaleRadioButtonsProps } from "../../../../types/charts/chartTypeSettings/chartTypeSettings";

import { scalingList } from "../../../../constants/charts/multipleAxisConfig";

const ScaleRadioButtons: FC<ScaleRadioButtonsProps> = ({
  value,
  handleScaleChange,
  disabled,
}) => {
  return (
    <FormControl disabled={disabled}>
      <RadioGroup
        value={value}
        className="axis-scale custom-primary-icon-color"
        onChange={handleScaleChange}
      >
        {scalingList.map((scale) => (
          <FormControlLabel
            key={scale.name}
            value={scale.name}
            control={<Radio />}
            label={scale.displayName}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default ScaleRadioButtons;
