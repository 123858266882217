import { ChartAxisDataFields } from "../../types/charts/chartType/chartType";
import { Attribute } from "../../types/common/attributes";

import * as attribute from "../attributes";
import { DEFAULT_GRADIENT_COLORS } from "../map/mapSettings";

export const SCATTER_PLOT_WELL_X_AXIS_ATTRIBUTES: ChartAxisDataFields[] = [
  {
    name: attribute.MEASURED_DEPTH.key,
    displayName: attribute.MEASURED_DEPTH.label,
    dataType: attribute.MEASURED_DEPTH.dataType,
  },
  {
    name: attribute.TOTAL_VERTICAL_DEPTH.key,
    displayName: attribute.TOTAL_VERTICAL_DEPTH.label,
    dataType: attribute.TOTAL_VERTICAL_DEPTH.dataType,
  },
  {
    name: attribute.PERF_INTERVAL_TOP.key,
    displayName: attribute.PERF_INTERVAL_TOP.label,
    dataType: attribute.PERF_INTERVAL_TOP.dataType,
  },
  {
    name: attribute.PERF_INTERVAL_BOTTOM.key,
    displayName: attribute.PERF_INTERVAL_BOTTOM.label,
    dataType: attribute.PERF_INTERVAL_BOTTOM.dataType,
  },
  {
    name: attribute.SPUD_DATE.key,
    displayName: attribute.SPUD_DATE.label,
    dataType: attribute.SPUD_DATE.dataType,
  },
  {
    name: attribute.TD_DATE.key,
    displayName: attribute.TD_DATE.label,
    dataType: attribute.TD_DATE.dataType,
  },
  {
    name: attribute.COMPLETION_DATE.key,
    displayName: attribute.COMPLETION_DATE.label,
    dataType: attribute.COMPLETION_DATE.dataType,
  },
  {
    name: attribute.PLUG_DATE.key,
    displayName: attribute.PLUG_DATE.label,
    dataType: attribute.PLUG_DATE.dataType,
  },
  {
    name: attribute.FIRST_MONTH.key,
    displayName: attribute.FIRST_MONTH.label,
    dataType: attribute.FIRST_MONTH.dataType,
  },
  {
    name: attribute.LAST_MONTH.key,
    displayName: attribute.LAST_MONTH.label,
    dataType: attribute.LAST_MONTH.dataType,
  },
  {
    name: attribute.LATERAL_LENGTH.key,
    displayName: attribute.LATERAL_LENGTH.label,
    dataType: attribute.LATERAL_LENGTH.dataType,
  },
  {
    name: attribute.FRAC_FLUID_AMOUNT.key,
    displayName: attribute.FRAC_FLUID_AMOUNT.label,
    dataType: attribute.FRAC_FLUID_AMOUNT.dataType,
  },
  {
    name: attribute.PROPPANT_AMOUNT.key,
    displayName: attribute.PROPPANT_AMOUNT.label,
    dataType: attribute.PROPPANT_AMOUNT.dataType,
  },
  {
    name: attribute.ACID_AMOUNT.key,
    displayName: attribute.ACID_AMOUNT.label,
    dataType: attribute.ACID_AMOUNT.dataType,
  },
  {
    name: attribute.PROPPANT_AMOUNT_PER_FT.key,
    displayName: attribute.PROPPANT_AMOUNT_PER_FT.label,
    dataType: attribute.PROPPANT_AMOUNT_PER_FT.dataType,
  },
  {
    name: attribute.FRAC_FLUID_AMOUNT_PER_FT.key,
    displayName: attribute.FRAC_FLUID_AMOUNT_PER_FT.label,
    dataType: attribute.FRAC_FLUID_AMOUNT_PER_FT.dataType,
  },
  {
    name: attribute.VSHALE.key,
    displayName: attribute.VSHALE.label,
    dataType: attribute.VSHALE.dataType,
  },
  {
    name: attribute.DENSITY.key,
    displayName: attribute.DENSITY.label,
    dataType: attribute.DENSITY.dataType,
  },
  {
    name: attribute.NEUTRON_POROSITY.key,
    displayName: attribute.NEUTRON_POROSITY.label,
    dataType: attribute.NEUTRON_POROSITY.dataType,
  },
  {
    name: attribute.RESISTIVITY.key,
    displayName: attribute.RESISTIVITY.label,
    dataType: attribute.RESISTIVITY.dataType,
  },
  {
    name: attribute.SONIC.key,
    displayName: attribute.SONIC.label,
    dataType: attribute.SONIC.dataType,
  },
];

export const SCATTER_PLOT_PERMIT_X_AXIS_ATTRIBUTES: ChartAxisDataFields[] = [
  {
    name: attribute.PERMIT_TOTAL_DEPTH.key,
    displayName: attribute.PERMIT_TOTAL_DEPTH.label,
    dataType: attribute.PERMIT_TOTAL_DEPTH.dataType,
  },
  {
    name: attribute.PERMIT_FORMATION_DEPTH.key,
    displayName: attribute.PERMIT_FORMATION_DEPTH.label,
    dataType: attribute.PERMIT_FORMATION_DEPTH.dataType,
  },
  {
    name: attribute.PERMIT_APPROVAL_DATE.key,
    displayName: attribute.PERMIT_APPROVAL_DATE.label,
    dataType: attribute.PERMIT_APPROVAL_DATE.dataType,
  },
  {
    name: attribute.PERMIT_POSTED_DATE.key,
    displayName: attribute.PERMIT_POSTED_DATE.label,
    dataType: attribute.PERMIT_POSTED_DATE.dataType,
  },
];

export const SCATTER_PLOT_WELL_PERMIT_X_AXIS_ATTRIBUTES: ChartAxisDataFields[] =
  [
    ...SCATTER_PLOT_WELL_X_AXIS_ATTRIBUTES,
    ...SCATTER_PLOT_PERMIT_X_AXIS_ATTRIBUTES,
  ];

//Y AXIS
export const SCATTER_PLOT_WELL_Y_AXIS_ATTRIBUTES: ChartAxisDataFields[] = [
  {
    name: attribute.MEASURED_DEPTH.key,
    displayName: attribute.MEASURED_DEPTH.label,
    dataType: attribute.MEASURED_DEPTH.dataType,
  },
  {
    name: attribute.TOTAL_VERTICAL_DEPTH.key,
    displayName: attribute.TOTAL_VERTICAL_DEPTH.label,
    dataType: attribute.TOTAL_VERTICAL_DEPTH.dataType,
  },
  {
    name: attribute.PERF_INTERVAL_TOP.key,
    displayName: attribute.PERF_INTERVAL_TOP.label,
    dataType: attribute.PERF_INTERVAL_TOP.dataType,
  },
  {
    name: attribute.PERF_INTERVAL_BOTTOM.key,
    displayName: attribute.PERF_INTERVAL_BOTTOM.label,
    dataType: attribute.PERF_INTERVAL_BOTTOM.dataType,
  },
  {
    name: attribute.LATERAL_LENGTH.key,
    displayName: attribute.LATERAL_LENGTH.label,
    dataType: attribute.LATERAL_LENGTH.dataType,
  },
  {
    name: attribute.FRAC_FLUID_AMOUNT.key,
    displayName: attribute.FRAC_FLUID_AMOUNT.label,
    dataType: attribute.FRAC_FLUID_AMOUNT.dataType,
  },
  {
    name: attribute.PROPPANT_AMOUNT.key,
    displayName: attribute.PROPPANT_AMOUNT.label,
    dataType: attribute.PROPPANT_AMOUNT.dataType,
  },
  {
    name: attribute.ACID_AMOUNT.key,
    displayName: attribute.ACID_AMOUNT.label,
    dataType: attribute.ACID_AMOUNT.dataType,
  },
  {
    name: attribute.PROPPANT_AMOUNT_PER_FT.key,
    displayName: attribute.PROPPANT_AMOUNT_PER_FT.label,
    dataType: attribute.PROPPANT_AMOUNT_PER_FT.dataType,
  },
  {
    name: attribute.FRAC_FLUID_AMOUNT_PER_FT.key,
    displayName: attribute.FRAC_FLUID_AMOUNT_PER_FT.label,
    dataType: attribute.FRAC_FLUID_AMOUNT_PER_FT.dataType,
  },
  {
    name: attribute.BOE_MAX_PER_FT.key,
    displayName: attribute.BOE_MAX_PER_FT.label,
    dataType: attribute.BOE_MAX_PER_FT.dataType,
  },
  {
    name: attribute.EUR_PER_FT.key,
    displayName: attribute.EUR_PER_FT.label,
    dataType: attribute.EUR_PER_FT.dataType,
  },
  {
    name: attribute.OIL_EUR_PER_FT.key,
    displayName: attribute.OIL_EUR_PER_FT.label,
    dataType: attribute.OIL_EUR_PER_FT.dataType,
  },
  {
    name: attribute.GAS_EUR_PER_FT.key,
    displayName: attribute.GAS_EUR_PER_FT.label,
    dataType: attribute.GAS_EUR_PER_FT.dataType,
  },
  {
    name: attribute.OIL_CUM_PER_FT.key,
    displayName: attribute.OIL_CUM_PER_FT.label,
    dataType: attribute.OIL_CUM_PER_FT.dataType,
  },
  {
    name: attribute.GAS_CUM_PER_FT.key,
    displayName: attribute.GAS_CUM_PER_FT.label,
    dataType: attribute.GAS_CUM_PER_FT.dataType,
  },
  {
    name: attribute.WATER_CUM_PER_FT.key,
    displayName: attribute.WATER_CUM_PER_FT.label,
    dataType: attribute.WATER_CUM_PER_FT.dataType,
  },
  {
    name: attribute.BOE_CUM_PER_FT.key,
    displayName: attribute.BOE_CUM_PER_FT.label,
    dataType: attribute.BOE_CUM_PER_FT.dataType,
  },
  {
    name: attribute.MAX_BOE.key,
    displayName: attribute.MAX_BOE.label,
    dataType: attribute.MAX_BOE.dataType,
  },
  {
    name: attribute.BOE_IP90.key,
    displayName: attribute.BOE_IP90.label,
    dataType: attribute.BOE_IP90.dataType,
  },
  {
    name: attribute.CUM_BOE.key,
    displayName: attribute.CUM_BOE.label,
    dataType: attribute.CUM_BOE.dataType,
  },
  {
    name: attribute.CUM_GAS.key,
    displayName: attribute.CUM_GAS.label,
    dataType: attribute.CUM_GAS.dataType,
  },
  {
    name: attribute.MAX_GAS.key,
    displayName: attribute.MAX_GAS.label,
    dataType: attribute.MAX_GAS.dataType,
  },
  {
    name: attribute.MAX_GAS_PLUS_2.key,
    displayName: attribute.MAX_GAS_PLUS_2.label,
    dataType: attribute.MAX_GAS_PLUS_2.dataType,
  },
  {
    name: attribute.CUM_OIL.key,
    displayName: attribute.CUM_OIL.label,
    dataType: attribute.CUM_OIL.dataType,
  },
  {
    name: attribute.MAX_OIL.key,
    displayName: attribute.MAX_OIL.label,
    dataType: attribute.MAX_OIL.dataType,
  },
  {
    name: attribute.MAX_OIL_PLUS_2.key,
    displayName: attribute.MAX_OIL_PLUS_2.label,
    dataType: attribute.MAX_OIL_PLUS_2.dataType,
  },
  {
    name: attribute.CUM_WATER.key,
    displayName: attribute.CUM_WATER.label,
    dataType: attribute.CUM_WATER.dataType,
  },
  {
    name: attribute.MAX_WATER.key,
    displayName: attribute.MAX_WATER.label,
    dataType: attribute.MAX_WATER.dataType,
  },
  {
    name: attribute.MAX_WATER_PLUS_2.key,
    displayName: attribute.MAX_WATER_PLUS_2.label,
    dataType: attribute.MAX_WATER_PLUS_2.dataType,
  },
  {
    name: attribute.WATER_CUT_TOTAL_CUM.key,
    displayName: attribute.WATER_CUT_TOTAL_CUM.label,
    dataType: attribute.WATER_CUT_TOTAL_CUM.dataType,
  },
  {
    name: attribute.CUM_GOR.key,
    displayName: attribute.CUM_GOR.label,
    dataType: attribute.CUM_GOR.dataType,
  },
  {
    name: attribute.CUM_YIELD.key,
    displayName: attribute.CUM_YIELD.label,
    dataType: attribute.CUM_YIELD.dataType,
  },
  {
    name: attribute.CUM_INJECTION_LIQUID.key,
    displayName: attribute.CUM_INJECTION_LIQUID.label,
    dataType: attribute.CUM_INJECTION_LIQUID.dataType,
  },
  {
    name: attribute.CUM_INJECTION_GAS.key,
    displayName: attribute.CUM_INJECTION_GAS.label,
    dataType: attribute.CUM_INJECTION_GAS.dataType,
  },
  {
    name: attribute.CUM_VENT_FLARE_VOL.key,
    displayName: attribute.CUM_VENT_FLARE_VOL.label,
    dataType: attribute.CUM_VENT_FLARE_VOL.dataType,
  },
  {
    name: attribute.WELL_FORECAST_BOE_REMAINING.key,
    displayName: attribute.WELL_FORECAST_BOE_REMAINING.label,
    dataType: attribute.WELL_FORECAST_BOE_REMAINING.dataType,
  },
  {
    name: attribute.WELL_FORECAST_BOE_ULTIMATE.key,
    displayName: attribute.WELL_FORECAST_BOE_ULTIMATE.label,
    dataType: attribute.WELL_FORECAST_BOE_ULTIMATE.dataType,
  },
  {
    name: attribute.WELL_FORECAST_GAS_REMAINING.key,
    displayName: attribute.WELL_FORECAST_GAS_REMAINING.label,
    dataType: attribute.WELL_FORECAST_GAS_REMAINING.dataType,
  },
  {
    name: attribute.WELL_FORECAST_GAS_ULTIMATE.key,
    displayName: attribute.WELL_FORECAST_GAS_ULTIMATE.label,
    dataType: attribute.WELL_FORECAST_GAS_ULTIMATE.dataType,
  },
  {
    name: attribute.WELL_FORECAST_OIL_REMAINING.key,
    displayName: attribute.WELL_FORECAST_OIL_REMAINING.label,
    dataType: attribute.WELL_FORECAST_OIL_REMAINING.dataType,
  },
  {
    name: attribute.WELL_FORECAST_OIL_ULTIMATE.key,
    displayName: attribute.WELL_FORECAST_OIL_ULTIMATE.label,
    dataType: attribute.WELL_FORECAST_OIL_ULTIMATE.dataType,
  },
  {
    name: attribute.WELL_FORECAST_WATER_REMAINING.key,
    displayName: attribute.WELL_FORECAST_WATER_REMAINING.label,
    dataType: attribute.WELL_FORECAST_WATER_REMAINING.dataType,
  },
  {
    name: attribute.WELL_FORECAST_WATER_ULTIMATE.key,
    displayName: attribute.WELL_FORECAST_WATER_ULTIMATE.label,
    dataType: attribute.WELL_FORECAST_WATER_ULTIMATE.dataType,
  },
  {
    name: attribute.WELL_FORECAST_GOR_ULTIMATE.key,
    displayName: attribute.WELL_FORECAST_GOR_ULTIMATE.label,
    dataType: attribute.WELL_FORECAST_GOR_ULTIMATE.dataType,
  },
  {
    name: attribute.WELL_FORECAST_YIELD_ULTIMATE.key,
    displayName: attribute.WELL_FORECAST_YIELD_ULTIMATE.label,
    dataType: attribute.WELL_FORECAST_YIELD_ULTIMATE.dataType,
  },
  {
    name: attribute.WELL_FORECAST_WATER_CUT_ULTIMATE.key,
    displayName: attribute.WELL_FORECAST_WATER_CUT_ULTIMATE.label,
    dataType: attribute.WELL_FORECAST_WATER_CUT_ULTIMATE.dataType,
  },
  {
    name: attribute.VSHALE.key,
    displayName: attribute.VSHALE.label,
    dataType: attribute.VSHALE.dataType,
  },
  {
    name: attribute.DENSITY.key,
    displayName: attribute.DENSITY.label,
    dataType: attribute.DENSITY.dataType,
  },
  {
    name: attribute.NEUTRON_POROSITY.key,
    displayName: attribute.NEUTRON_POROSITY.label,
    dataType: attribute.NEUTRON_POROSITY.dataType,
  },
  {
    name: attribute.RESISTIVITY.key,
    displayName: attribute.RESISTIVITY.label,
    dataType: attribute.RESISTIVITY.dataType,
  },
  {
    name: attribute.SONIC.key,
    displayName: attribute.SONIC.label,
    dataType: attribute.SONIC.dataType,
  },
];

export const SCATTER_PLOT_PERMIT_Y_AXIS_ATTRIBUTES: ChartAxisDataFields[] = [
  {
    name: attribute.PERMIT_TOTAL_DEPTH.key,
    displayName: attribute.PERMIT_TOTAL_DEPTH.label,
    dataType: attribute.PERMIT_TOTAL_DEPTH.dataType,
  },
  {
    name: attribute.PERMIT_FORMATION_DEPTH.key,
    displayName: attribute.PERMIT_FORMATION_DEPTH.label,
    dataType: attribute.PERMIT_FORMATION_DEPTH.dataType,
  },
];

export const SCATTER_PLOT_WELL_PERMIT_Y_AXIS_ATTRIBUTES: ChartAxisDataFields[] =
  [
    ...SCATTER_PLOT_WELL_Y_AXIS_ATTRIBUTES,
    ...SCATTER_PLOT_PERMIT_Y_AXIS_ATTRIBUTES,
  ];

//COLOR BY
export const SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE: {
  [key: string]: Attribute;
} = {
  LEASE_NAME: attribute.LEASE_NAME,
  FIELD_NAME: attribute.FIELD_NAME,
  SLANT: attribute.SLANT,
  WELL_STATUS: attribute.WELL_STATUS,
  WELL_TYPE: attribute.WELL_TYPE,
  OPERATOR_NAME: attribute.OPERATOR_NAME,
  DISPLAY_FORMATION: attribute.DISPLAY_FORMATION,
  ULTIMATE_OWNER: attribute.ULTIMATE_OWNER,
};

export const SCATTER_PLOT_PERMITS_ATTRIBUTES: Attribute[] = [
  attribute.PERMIT_TOTAL_DEPTH,
  attribute.PERMIT_FORMATION_DEPTH,
  attribute.PERMIT_APPROVAL_DATE,
  attribute.PERMIT_POSTED_DATE,
];

export const SCATTER_PLOT_WELL_COLOR_BY_ATTRIBUTES: Attribute[] = [
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["LEASE_NAME"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["FIELD_NAME"],
  attribute.BASIN_NAME,
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["SLANT"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["WELL_STATUS"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["WELL_TYPE"],
  attribute.STATE_LEASE_ID,
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["OPERATOR_NAME"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["ULTIMATE_OWNER"],
  attribute.INTERPRETED_PRODUCING_FORMATION,
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["DISPLAY_FORMATION"],
  attribute.MEASURED_DEPTH,
  attribute.TOTAL_VERTICAL_DEPTH,
  attribute.PLUG_DATE,
  attribute.SPUD_DATE,
  attribute.TD_DATE,
  attribute.FIRST_MONTH,
  attribute.LAST_MONTH,
  attribute.COMPLETION_DATE,
  attribute.LATERAL_LENGTH,
  attribute.PERF_INTERVAL_TOP,
  attribute.PERF_INTERVAL_BOTTOM,
  attribute.ACID_AMOUNT,
  attribute.FRAC_FLUID_AMOUNT,
  attribute.FRAC_FLUID_AMOUNT_PER_FT,
  attribute.PROPPANT_AMOUNT,
  attribute.PROPPANT_AMOUNT_PER_FT,
  attribute.OIL_CUM_PER_FT,
  attribute.OIL_EUR_PER_FT,
  attribute.MAX_OIL,
  attribute.MAX_OIL_PLUS_2,
  attribute.WELL_FORECAST_OIL_REMAINING,
  attribute.CUM_OIL,
  attribute.WELL_FORECAST_OIL_ULTIMATE,
  attribute.GAS_CUM_PER_FT,
  attribute.GAS_EUR_PER_FT,
  attribute.MAX_GAS,
  attribute.MAX_GAS_PLUS_2,
  attribute.WELL_FORECAST_GAS_REMAINING,
  attribute.CUM_GAS,
  attribute.WELL_FORECAST_GAS_ULTIMATE,
  attribute.BOE_CUM_PER_FT,
  { ...attribute.EUR_PER_FT, label: "(BOE) EUR Per Lateral Length" },
  attribute.MAX_BOE,
  attribute.BOE_MAX_PER_FT,
  attribute.BOE_IP90,
  attribute.WELL_FORECAST_BOE_REMAINING,
  attribute.CUM_BOE,
  attribute.WELL_FORECAST_BOE_ULTIMATE,
  attribute.MAX_WATER,
  attribute.MAX_WATER_PLUS_2,
  attribute.WELL_FORECAST_WATER_REMAINING,
  attribute.CUM_WATER,
  attribute.WELL_FORECAST_WATER_ULTIMATE,
  attribute.WATER_CUT_TOTAL_CUM,
  attribute.WELL_FORECAST_WATER_CUT_ULTIMATE,
  attribute.CUM_GOR,
  attribute.WELL_FORECAST_GOR_ULTIMATE,
  attribute.CUM_YIELD,
  attribute.WELL_FORECAST_YIELD_ULTIMATE,
  attribute.CUM_VENT_FLARE_VOL,
  attribute.DISPOSITION_TYPE,
  attribute.LAST_INJECTION_TYPE,
  attribute.CUM_INJECTION_GAS,
  attribute.CUM_INJECTION_LIQUID,
  attribute.DENSITY,
  attribute.NEUTRON_POROSITY,
  attribute.VSHALE,
  attribute.SONIC,
  attribute.RESISTIVITY,
];

export const SCATTER_PLOT_PERMIT_COLOR_BY_ATTRIBUTES: Attribute[] = [
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["LEASE_NAME"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["FIELD_NAME"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["SLANT"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["WELL_STATUS"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["WELL_TYPE"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["OPERATOR_NAME"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["ULTIMATE_OWNER"],
  SCATTER_PLOT_SHARED_COLOR_BY_ATTRIBUTE["DISPLAY_FORMATION"],
  ...SCATTER_PLOT_PERMITS_ATTRIBUTES,
];

export const SCATTER_PLOT_WELL_PERMIT_COLOR_BY_ATTRIBUTES: Attribute[] = [
  ...SCATTER_PLOT_WELL_COLOR_BY_ATTRIBUTES,
  ...SCATTER_PLOT_PERMITS_ATTRIBUTES,
];

export const allOthersKey = "All Others";
export const minGroup = "min";
export const maxGroup = "max";
export const maxValColor = DEFAULT_GRADIENT_COLORS[1];
export const minValColor = DEFAULT_GRADIENT_COLORS[0];
